import React from 'react';
import { Link } from 'react-router-dom';
import studentAdvisorImage from 'images/gradstudent.jpeg';
import styles from 'styles/JoinTheTeam.module.css'
import classNames from 'classnames';

function JoinStudentAdvisor() {
    return (
        <div className={styles.page}>
            <div className={styles.section}>
                <div className={styles.content}>
                    <h2>Introducing Student Advisors on ClearPillar</h2>
                    <p>At ClearPillar, we believe in providing the most authentic and firsthand insights into colleges and universities. That's why we've introduced Student Advisors—a unique opportunity for prospective students to connect with current students from their desired schools.</p>

                    <div className={styles.info}>
                        <div className={styles.text}>
                            <h3>What is a Student Advisor?</h3>
                            <p>A Student Advisor is a current student from a specific college or university who offers consultations about their school. These advisors provide real-time information and personal experiences to help prospective students understand what it’s really like to be a student there.</p>
                        </div>
                        <div className={styles.image}>
                            <img src={studentAdvisorImage} alt="Student Advisor" />
                        </div>
                    </div>

                    <div className={styles.notepad}>
                        <h3>What Can You Learn from a Student Advisor?</h3>
                        <ul>
                            <li><strong>School Mission and Values:</strong> Gain insights into the school’s mission, values, and overall environment.</li>
                            <li><strong>Student Life:</strong> Discover what daily life is like on campus, including clubs, organizations, and social activities.</li>
                            <li><strong>Application Process:</strong> Learn how they successfully applied to the school, including tips and strategies.</li>
                            <li><strong>Academic Environment:</strong> Understand the academic rigor, support systems, and resources available.</li>
                            <li><strong>Personal Advice:</strong> Receive personalized advice on how to prepare and stand out in your application.</li>
                        </ul>
                    </div>

                    <h3>How It Works</h3>
                    <ol>
                        <li><strong>Book a Session:</strong> Choose a Student Advisor from your desired school and book a 1-hour session.</li>
                        <li><strong>Ask Questions:</strong> Prepare any questions you have about the school, student life, or the application process.</li>
                        <li><strong>Get Real-Time Insights:</strong> Receive honest and detailed answers directly from a current student.</li>
                        <li><strong>Session Fees:</strong> Each session costs $30, providing you with valuable, firsthand information.</li>
                    </ol>

                    <p>Our Student Advisors are here to bridge the gap between prospective students and their dream schools. Whether you’re curious about the campus culture, academic expectations, or simply want to know what it’s like to live there, our Student Advisors can provide the insights you need to make informed decisions.</p>

                    <h3>Join Our Team as a Student Advisor</h3>

                    <h4>Why Become a Student Advisor?</h4>
                    <ul>
                        <li><strong>Share Your Experience:</strong> Help prospective students by sharing your firsthand experiences and insights about your school.</li>
                        <li><strong>Flexible Hours:</strong> Set your own schedule and work as much or as little as you want.</li>
                        <li><strong>Earn Money:</strong> Earn $30 per session by providing valuable guidance to students.</li>
                        <li><strong>Build Your Resume:</strong> Gain experience in mentorship, communication, and leadership.</li>
                    </ul>

                    <p><strong>Join our team today and make a difference in the lives of future college students!</strong></p>

                    <div className={styles.container}>
                        <Link to="/register/studentadvisor-register" className={classNames("btn", styles.btn)}> Apply to be a Student Advisor </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinStudentAdvisor;
