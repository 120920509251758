import React, { useState, useEffect } from 'react';
import slides from 'constants/slides'
import 'styles/howitworks.css'

export default function HowItWorks() {
  /* slide show */
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 6000); // 4 sec

    return () => clearInterval(interval);
  }, []);

  /* file moving animation */
  useEffect(() => {
    if (currentSlide === 1) {
      document.querySelector('.file-animation').classList.add('animate-file');
    } else {
      document.querySelector('.file-animation').classList.remove('animate-file');
    }
  }, [currentSlide]);

  /* confetti animation */
  // useEffect(() => {
  //   if (currentSlide === 3) { 
  //     const confettiInterval = setInterval(() => {
  //       confetti({
  //         particleCount: 100,
  //         spread: 70,
  //         origin: { y: 0.6 },
  //         // Ensure confetti stays within container
  //         bounds: {
  //           top: 0,
  //           left: 0,
  //           right: window.innerWidth,
  //           bottom: window.innerHeight
  //         }
  //       });
  //     }, 3500); // 3.5 seconds

  //     return () => clearInterval(confettiInterval); 
  //   }
  // }, [currentSlide]);

  return (
    <div className="how-it-works">
      <div className="slides-container">
        <div className="slides" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              <h2>{slide.text}</h2>
              {slide.icon && <div className="slide-icon">{slide.icon}</div>}
            </div>
          ))}
        </div>
        <button className="prev-btn" onClick={handlePrev}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <button className="next-btn" onClick={handleNext}>
          <i className="fa-solid fa-chevron-right"></i>
        </button>
        <div className="dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
}
