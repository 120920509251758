import React from 'react';
import styles from 'styles/AboutUs.module.css'

export default function AboutUs() {
  return (
    <div className={styles.page}>

      {/* Banner img */}
      <section className={styles.hero}>
        <div className={styles.overlay}></div>
      </section>

      {/* About Us */}
      <div className={styles.container}>
        <h1 className={styles.header}>ABOUT US</h1>
        <div className={styles.section}>

          {/* Our Story */}
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Story</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
          <div className={styles["line-margin"]}>
            <hr className={styles.line} />
          </div>
          <p>
            ClearPillar shapes youth into extraordinary leaders by guiding them to the best-fit
            colleges where they can maximize their potential and receive a top-tier education,
            while also fostering excellence in leadership, entrepreneurship, and community service
            throughout their journey.
          </p>
          <p>
            ClearPillar was founded to bridge the gap between Asian families and
            college admissions professionals. Our mission is to offer personalized
            support, ensuring students and families have the resources and guidance
            needed for a successful academic journey.
          </p>
          <p>
            ClearPillar's journey began with recognizing the unique challenges
            Asian families face in navigating college admissions. With a
            commitment to education and community, ClearPillar provides
            tailored support and expert guidance. Our founder established
            ClearPillar to address the critical need for resources often
            inaccessible to Asian families due to language barriers and
            cultural differences. Many parents find themselves left out of
            the loop because of difficulties in communicating with both the
            mainstream marketplace and their own children. ClearPillar bridges
            this gap, ensuring parents stay informed and involved in their child's
            academic journey while respecting the student's privacy and personal
            stories.
          </p>
        </div>

        {/* ***IN FUTURE, REPLACE WITH "TEAM"*** */}
        {/* <div className={styles.section}>
          <div className={styles["founder-section"]}>
            <div className="container mt-4">
              <div className="row">
                <div className="col">
                  <div className={styles.founder}>
                    <img src='#'></img>
                  </div>
                </div>
                <div className="col">
                  <div className={styles.founder}>
                    <div className={styles["star-container"]}>
                      <h2 className="me-3">Our Founder</h2>
                      <div className={styles["star-items"]}>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                    <div className={styles["line-margin"]}>
                      <hr className={styles.line} />
                    </div>
                    <h2>Rachel Lam</h2>
                    <p className='mt-4'>
                      With a BS degree in Legal Studies and 12 years of experience in a
                      law firm, our founder transitioned to becoming a licensed financial
                      professional. Early on, the need for effective tuition planning
                      became evident. Despite extensive training, the complexities of
                      financial solutions for early planning remained unclear. Driven by a
                      desire to provide meaningful assistance, our founder embarked on a
                      journey of self-education, utilizing online resources, certifications,
                      and non-degree courses from institutions like Columbia University.
                    </p>
                    <p>
                      In 2023, this dedication culminated in the opportunity to lead
                      college planning seminars hosted by three nonprofit organizations.
                      These seminars attracted significant attention from the Asian
                      community, highlighting a demand for comprehensive, transparent
                      college advisory services. The positive feedback and gratitude
                      from over 30 families underscored the effectiveness of ClearPillar’s
                      approach.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className={styles.section2}>
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Pillars</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>

          <div className={styles["line-margin"]}>
            <hr className={styles.line} />
          </div>
          <p className='mt-2 text-center'>ClearPillar’s servers are designed to address
            the specific needs of Asian families. ClearPillar coveres all
            aspects of the college process.<br />We focus on three main areas:
          </p>

          <div className="container mt-5">
            <div className="row">
              <div className="col-sm">
                <h2>01</h2>
                <h3>Admission Strategy</h3>
                <p>Connecting families with former admissions officers from
                  prestigious institutions like Princeton and MIT, providing
                  insider insights and tailored application strategies.</p>
              </div>
              <div className="col-sm">
                <h2>02</h2>
                <h3>Essay Assistance</h3>
                <p>
                  Offering specialized support for essay brainstorming and
                  editing, ensuring students present their best selves in
                  their applications.
                </p>
              </div>
              <div className="col-sm">
                <h2>03</h2>
                <h3>Tuition Planning</h3>
                <p>
                  Providing early planning advice to help families navigate
                  financial aid and optimize their financial profiles for
                  maximum aid eligibility.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <ul>
            <li>
              <b>Admission Strategy:</b>
              Connecting families with former admissions officers from
              prestigious institutions like Princeton and MIT, providing
              insider insights and tailored application strategies.
            </li>
            <li>
              <b>Essay Assistance: </b>
              Offering specialized support for essay brainstorming and
              editing, ensuring students present their best selves in
              their applications.
            </li>
            <li>
              <b>Tuition Planning: </b>
              Providing early planning advice to help families navigate
              financial aid and optimize their financial profiles for
              maximum aid eligibility.
            </li>
          </ul> */}

        {/* <div className={styles.section2}>
          <h2>Our Approach</h2>
          <p>
            ClearPillar believes in matching the best talent with students,
            ensuring their time and resources are well-spent.
            Our liaison system bridges the communication gap, allowing
            families to receive clear and valuable guidance. Each liaison
            coordinates with professionals to provide a seamless,
            effective service experience.
          </p>
        </div> */}

        {/* <div className={styles.section}>
          <h2>Our Vision</h2>
          <p>
            At ClearPillar, our vision is to shape youth into extraordinary
            leaders by guiding them to the best-fit colleges where they
            can maximize their potential and receive a top-tier education.
            ClearPillar is a vital component of a broader journey that
            encourages youth to excel in leadership positions,
            entrepreneurship ventures, and community service.
          </p>
          <p>
            We offer specialized services and support independent consultants
            to create a win-win situation. Our professionals are compensated
            for their expertise, while families receive unparalleled guidance.
            Our structured system ensures consistency and excellence,
            fostering a supportive community for academic and personal growth.
          </p>
          <p>
            Join ClearPillar as a specialist in your field and become part
            of a dedicated team committed to making a difference. Our
            independent consultants work with liaisons to provide
            exceptional service, ensuring every student has the
            opportunity to succeed.
          </p>
        </div> */}

        {/* Our Approach */}
        <div className={styles.section}>
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Approach</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
          <div className={styles["line-margin"]}>
            <hr className={styles.line} />
          </div>
          <p className='mt-2'>
            ClearPillar believes in matching the best talent with students,
            ensuring their time and resources are well-spent.
            Our liaison system bridges the communication gap, allowing families
            to receive clear and valuable guidance. Each liaison coordinates with professionals
            to provide a seamless, effective service experience.
          </p>

          <p>
            Our professionals are compensated for their expertise,
            while families receive unparalleled guidance. Our structured
            system ensures consistency and excellence, fostering a supportive
            community for academic and personal growth.
          </p>
        </div>

        {/* Our Iniviatives*/}
        <div className={styles.section}>
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Initiatives</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
          <div className={styles["line-margin"]}>
            <hr className={styles.line} />
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className={styles["initiative-item"]}>
                <div className={styles.icon}>
                  <i className="fa-3x fa-solid fa-user-tie"></i>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className={styles["initiative-text"]}>
                <h3>Advisor Specialization</h3>
                <p>
                  Each advisor on our platform has a detailed profile that
                  highlights their areas of expertise, years of experience,
                  and success stories. This allows families to choose advisors
                  who best meet their unique needs, ensuring personalized
                  and effective guidance.
                </p>
              </div>
            </div>
            <hr className={styles["initiative-divider"]} />
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className={styles["initiative-item"]}>
                <div className={styles.icon}>
                  <i className="fa-3x fa-solid fa-people-arrows"></i>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className={styles["initiative-text"]}>
                <h3>Client Matching</h3>
                <p>
                  We enable liaisons to select advisors based on the
                  specific needs of their clients. This targeted approach
                  helps maximize the success and satisfaction of our clients.
                </p>
              </div>
            </div>
            <hr className={styles["initiative-divider"]} />
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className={styles["initiative-item"]}>
                <div className={styles.icon}>
                  <i className="fa-3x fa-solid fa-comment-dots"></i>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className={styles["initiative-text"]}>
                <h3>Client Feedback System</h3>
                <p>
                  Your feedback is crucial to our continuous improvement.
                  We encourage you to share your experiences with our advisors,
                  helping us maintain high service standards and tailor our
                  platform to better meet your needs. Your input ensures we
                  provide the best possible support and guidance.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={styles.section2}>
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Vision</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>

          <div className={styles["line-margin"]}>
            <hr className={styles.line} />
          </div>
          <p className='mt-2'>
            ClearPillar shapes youth into extraordinary leaders by
            guiding them to the best-fit colleges where they can
            maximize their potential and receive a top-tier education,
            while also fostering excellence in leadership, entrepreneurship,
            and community service throughout their journey.
          </p>
          <p>
            Join ClearPillar as a specialist in your field
            and become part of a dedicated team committed to making
            a difference. Our independent consultants work with liaisons
            to provide tailored exceptional service, ensuring every student
            has the opportunity to be the best version of themselves.succeed.
          </p>
          <p>
            Believe your specialty can be a pillar to our team?
            Join ClearPillar as a student advisor or strategist.
            As an independent consultant, you will collaborate with our
            liaisons to provide tailored exceptional service, ensuring
            every student has the opportunity to thrive.
          </p>
        </div> */}

        {/* Our Commitment */}
        <div className={styles.section}>
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Commitment</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
          <div className={styles["line-margin"]}>
            <hr className={styles.line} />
          </div>

          <p className='mt-2'>At ClearPillar, we are dedicated to matching each student
            with the most suitable professional tailored to your unique
            needs to ensure you receive the best possible guidance.
          </p>

          <div className="container">
            {/* <div className="row pt-3 pb-5">
              <div className="col">
                <h3>Cultural Sensitivity</h3>
                <p>
                  Effective communication is the cornerstone to our approach,
                  acknowledging the vital role parents play in their child's education.
                  We prioritize language accessibility, with liaisons fluent in families'
                  preferred languages, and provide detailed progress reports throughout a
                  student's journey, keeping parents informed while culturally bridging gaps
                  between students' experiences and parents' expectations.
                </p>
              </div>
            </div> */}

            <div className="row pt-5 pb-5">
              <div className="col">
                {/* <h3>Communication with Parents</h3>
                <p>
                  Effective communication is the cornerstone to our approach,
                  acknowledging the vital role parents play in their child's education.
                  We prioritize language accessibility, with liaisons fluent in families'
                  preferred languages, and provide detailed progress reports throughout a student's journey,
                  keeping parents informed while culturally bridging gaps between students' experiences and parents' expectations.
                </p> */}
                <div className="container mt-5">
                  <div className="row">
                    <div className="col-sm">
                      <h2>01</h2>
                      <h3>Language Accessibility</h3>
                      <p>Our liaisons are fluent in the preferred languages of the families we serve, 
                        ensuring that language barriers do not hinder communication. This allows 
                        Asian parents to be fully engaged and informed about their child's 
                        progress and the steps being taken.
                      </p>
                    </div>
                    <div className="col-sm">
                      <h2>02</h2>
                      <h3>Regular Updates</h3>
                      <p>
                        We provide consistent updates to parents about their child's progress, 
                        ensuring they are kept in the loop. This includes detailed reports on 
                        milestones achieved, areas of improvement, and future plans.
                      </p>
                    </div>
                    <div className="col-sm">
                      <h2>03</h2>
                      <h3>Cultural Sensitivity</h3>
                      <p>
                        Understanding the cultural nuances and expectations is crucial. 
                        Our culturally sensitive approach ensures that parents feel 
                        comfortable and respected throughout the process. We bridge any 
                        gaps between the students' experiences and the parents' expectations, 
                        fostering a supportive and harmonious environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
