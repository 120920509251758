import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from 'utils/ScrollToTop';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Home from '@home/Home';
import ForgotPassword from '@auth/ForgotPassword';
import ResetPassword from '@auth/ResetPassword';
import InterestForm from '@home/InterestForm';
import ThickLiaison from '@thickliaison/ThickLiaison';
import InterestSuccess from '@home/InterestSuccess';
import AboutUs from '@about/AboutUs';
import CommunityImpact from '@about/CommunityImpact';
import Privacy from '@about/Privacy';
import Roadmap from '@student-resources/Roadmap';
import Workshops from '@student-resources/Workshops';
import BusinessModel from '@join-the-team/BusinessModel';
import EssayBrainstorming from '@student-resources/EssayBrainstorming';
import LoginPage from '@auth/LoginPage';
import StrategistRegister from '@auth/StrategistRegister';
import LiaisonRegister from '@auth/LiaisonRegister';
import StudentRegister from '@auth/StudentRegister';
import StudentAdvisorRegister from '@auth/StudentAdvisorRegister';
import JoinStudentAdvisor from '@join-the-team/JoinStudentAdvisor';
import JoinSpecialist from '@join-the-team/JoinSpecialist';
import JoinStrategist from '@join-the-team/JoinStrategist';
import JoinLiaison from '@join-the-team/JoinLiaison';
import JoinCareerAdvisor from '@join-the-team/JoinCareerAdvisor';
import EssayAssistance from '@services/EssayAssistance';
import TuitionPlanning from '@services/TuitionPlanning';
import AdmissionStrategy from '@services/AdmissionStrategy';

// import OtherServices from '@services/OtherServices';
import Dashboard from '@profile/Dashboard';
import Settings from '@profile/Settings';
import Profile from '@profile/Profile';
import TestPage from '@test/TestPage';

import RegisterSuccess from 'components/RegisterSuccess';

// strategist
import StrategistDashboard from 'strategist/StrategistDashboard';
import { AuthProvider, useAuth } from 'contexts/AuthContext';

// liaison
import LiaisonDashboard from 'liaison/LiaisonDashboard';

//student 
import StudentDashboard from 'student/StudentDashboard';

//student advisor
import StudentAdvDashboard from 'studentAdvisor/StudentAdvDashboard';

// admin
import AdminDashboard from 'admin/AdminDashboard';
import ViewLiaisonProfiles from 'admin/ViewProfiles/ViewLiaisonProfiles';
import ViewStudentProfiles from 'admin/ViewProfiles/ViewStudentProfiles';
import ViewStrategistProfiles from 'admin/ViewProfiles/ViewStrategistProfiles';
import MatchStudentLiaison from 'admin/MatchStudentLiaison';
import ViewStudentMatch from 'admin/ViewStudentMatch';

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Navbar />
        <div>
          <Routes>
            {/* landing pages */}
            <Route path="/" element={<Home />} />
            <Route path="/testpage" element={<TestPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/thick-liaison" element={<ThickLiaison />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/student-interest-form" element={<InterestForm />} />
            <Route path='/interest-form-success' element={<InterestSuccess />} />
            <Route path="/community-impact" element={<CommunityImpact />} />
            <Route path="/services/admissions-strategy" element={<AdmissionStrategy />} />
            <Route path="/services/essay-assistance" element={<EssayAssistance />} />
            <Route path="/services/tuition-planning" element={<TuitionPlanning />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            
            {/* <Route path="/services/other-services" element={<OtherServices />} /> */}
            <Route path="/resources/roadmap" element={<Roadmap />} />
            <Route path="/resources/essay-brainstorming" element={<EssayBrainstorming />} />
            <Route path="/resources/workshops" element={<Workshops />} />
            <Route path="/opportunities/business-model" element={<BusinessModel />} />
            <Route path="/opportunities/join-student-advisor" element={<JoinStudentAdvisor />} />
            <Route path="/opportunities/join-strategist" element={<JoinStrategist />} />
            <Route path="/opportunities/join-specialist" element={<JoinSpecialist/>} />
            <Route path="/opportunities/join-liaison" element={<JoinLiaison />} />
            <Route path="/opportunities/join-career-advisor" element={<JoinCareerAdvisor />} />

            {/* Login/Registration */}
            {/* <Route path="/login" element={<LoginPage />} />
            <Route path="/register/strategist-register" element={<StrategistRegister />} />
            <Route path="/register/liaison-register" element={<LiaisonRegister />} />
            <Route path="/register/student-register" element={<StudentRegister />} />
            <Route path="/register/studentadvisor-register" element={<StudentAdvisorRegister />} /> */}

            {/* mockup dashboard pages */}
            {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} /> */}

            {/* strategist pages */}
            {/* <Route path="/register-success" element={ < RegisterSuccess/>} />
            <Route path="/strategist/strategist-dashboard" element={<StrategistDashboard />} /> */}
            {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} /> */}

            {/* liaison pages */}
            {/* <Route path="/register-success" element={ < RegisterSuccess/>} />
            <Route path="/liaison/liaison-dashboard" element={<LiaisonDashboard />} /> */}
            {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} /> */}

            {/* student pages */}
            {/* <Route path="/student/student-dashboard" element={<StudentDashboard />} /> */}

            {/* student advisor pages */}
            {/* <Route path="/studentAdvisor/student-adv-dashboard" element={<StudentAdvDashboard />} /> */}

            {/* admin pages */}
            <Route path="/admin/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/admin/view-liaison-profiles" element={<ViewLiaisonProfiles />} />            
            <Route path="/admin/view-liaison-profiles" element={<ViewLiaisonProfiles />} />
            <Route path="/admin/view-student-profiles" element={<ViewStudentProfiles />} />
            <Route path="/admin/view-strategist-profiles" element={<ViewStrategistProfiles />} />
            <Route path="/admin/match-student-liaison" element={<MatchStudentLiaison />} />
            <Route path="/admin/view-student-match" element={<ViewStudentMatch />} />

          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to="/login" />;
}


export default App;
