import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from 'styles/JoinTheTeam.module.css'
import classNames from 'classnames';

function JoinSpecialist() {
    const [flipped, setFlipped] = useState({
        join1: false,
        join2: false,
        join3: false,
        join4: false
    });

    const handleFlip = (card) => {
        setFlipped({
            ...flipped,
            [card]: !flipped[card],
        });
    };
    return (
        <div className={styles.page}>
            <div className={styles.section}>
                <div className={styles.content}>
                    <p>
                        At ClearPillar, we’re dedicated to offering the best college advisory services, which means having a team of specialized strategists who bring expertise in specific areas rather than a one-size-fits-all approach. We believe students benefit most when they’re supported by experts who are deeply knowledgeable in distinct areas. We are currently looking for talented strategists in the following specialties:
                    </p>

                    <div>
                        <h2>Available Specialist Roles:</h2>
                        <ol>
                            <li>Scholarship Specialist</li>
                            <ul>
                                <li>Responsibilities: Guide students in finding and applying for scholarships.</li>
                                <li>Goal: Ensure students have a strong, achievable college application plan while maximizing scholarship opportunities.</li>
                            </ul>
                            <li>Admission Strategy Development Specialist</li>
                            <ul>
                                <li>Responsibilities: Develop tailored admission strategies that highlight each student's strengths, interests, and unique profile.</li>
                                <li>Goal: Help students strategically position themselves for acceptance by understanding what each institution values.</li>
                            </ul>
                            <li>Essay Assistance Specialist</li>
                            <ul>
                                <li>Responsibilities: Work with students on drafting, refining, and finalizing their main and supplemental essays, including Common App essays. Emphasis is on storytelling and authenticity.</li>
                                <li>Goal: Guide students to create compelling essays that authentically represent who they are, making them stand out to admissions committees.</li>
                            </ul>
                            <li>Financial Aid Assistance Specialist</li>
                            <ul>
                                <li>Responsibilities: Support families with financial aid applications, including FAFSA and CSS Profile submissions, and offer guidance on writing appeal letters to request additional aid.</li>
                                <li>Goal: Maximize financial aid opportunities for families and alleviate the financial burden of college expenses.</li>
                            </ul>
                            <li>Tuition Planning Specialist</li>
                            <ul>
                                <li>Responsibilities: Offer tailored tuition planning and budgeting strategies that align with families' long-term financial goals.</li>
                                <li>Goal: Ensure families can manage college costs sustainably and avoid excessive debt.</li>
                            </ul>
                            <li>Resume Editing Specialist</li>
                            <ul>
                                <li>Responsibilities: Assist students in creating impactful resumes that effectively highlight their achievements, skills, and extracurriculars.</li>
                                <li>Goal: Equip students with polished resumes that enhance their applications and impress admissions committees.</li>
                            </ul>
                            <li>SAT/ACT Preparation Specialist</li>
                            <ul>
                                <li>Responsibilities: Provide tutoring and resources to help students maximize their performance on standardized tests.</li>
                                <li>Goal: Boost students’ scores to enhance their applications and increase their competitiveness.</li>
                            </ul>
                        </ol>
                    </div>

                    <hr />

                    <div>
                        <h2>ClearPillar’s Network of Specialized Advisors</h2>
                        <p>Our team of strategists includes a diverse network of experts, each with unique skills to support students’ application journeys:</p>
                        <ul>
                            <li>Essay Content and Storytelling Specialists: Experts in crafting personal stories that resonate. They help students discover and articulate compelling narratives that elevate their application essays.</li>
                            <li>Former Admissions Counselors: Professionals with admissions backgrounds who offer insider insights into what colleges seek. They guide students in building applications that reflect what admissions officers value most.</li>
                            <li>Licensed Financial Professionals and CPAs: Financial experts who support families with college cost planning, award letter reviews, and strategies to maximize scholarships and financial aid.</li>
                            <li>Student Advisors: Current or recent college students who share real-life perspectives on college life and application tips, providing relatable guidance to applicants.</li>
                            <li>SAT/ACT Tutors: Tutors specializing in standardized test preparation who are dedicated to helping students achieve their best possible scores.</li>
                        </ul>
                    </div>

                    <hr />

                    <div>
                        <h3>Why Join ClearPillar as a Specialist?</h3>
                        <div className="row">
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join1 ? styles.flipped : ''}`} onClick={() => handleFlip('join1')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Focus on Your Expertise</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Concentrate on the area where you excel, providing a higher level of service.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join2 ? styles.flipped : ''}`} onClick={() => handleFlip('join2')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Impactful Contribution</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Support students by contributing specialized knowledge, helping them to succeed in the specific aspects of their application.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join3 ? styles.flipped : ''}`} onClick={() => handleFlip('join3')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Collaborative Environment</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Work alongside a team of professionals who bring complementary skills to offer a holistic approach to each student’s journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join4 ? styles.flipped : ''}`} onClick={() => handleFlip('join4')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Competitive Compensation</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Receive fair pay for your expertise and time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3>How It Works</h3>
                    <ol>
                        <li><strong>Apply:</strong> Submit your application to become a liaison at ClearPillar.</li>
                        <li><strong>Interview:</strong> Participate in an interview to discuss your skills and experience.</li>
                        <li><strong>Onboarding:</strong> Complete the onboarding process and start facilitating client-strategist interactions.</li>
                    </ol>

                    <h3>Ready to Join Our Team?</h3>
                    <p>
                        Join us in empowering students with a comprehensive, specialized approach to college admissions!
                    </p>

                    <p><b>Apply today and bring your expertise to ClearPillar’s mission of delivering unparalleled support to students and families.</b></p>

                    <div className={styles.container}>
                        <Link to="#" className={classNames("btn", styles.btn)}> Apply to be a Specialist </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinSpecialist;