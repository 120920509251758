function formatDateToTimeZone(utcDate, timeZone = 'America/New_York') {
    const date = new Date(utcDate);  // Convert the UTC string to a Date object

    const options = {
        timeZone: timeZone,            // Use the passed timezone (default to 'America/New_York')
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,                  // Use 12-hour format
    };

    return date.toLocaleString('en-US', options);  // Format the date and return it
}
export default formatDateToTimeZone;

// Example usage
// const utcDate = '2024-12-09T03:52:23.631Z';
// const formattedDate = formatDateToTimeZone(utcDate);
// console.log(formattedDate);