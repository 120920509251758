import React from 'react';

function TestPage(props) {
    return (
        <div style={{ color: 'white' }}>
            <h1>捲動開始您的旅程</h1>
            <h1>幫助美國亞裔青年</h1>
            <h1>提升並晉升領導和創業力</h1>
            <p>歡迎來到 THICK，這是一個以目標為導向的平台，
                致力於塑造年輕人的未來。我們引導亞洲青年通過一個整體的四步旅程，
                包括大學輔導、軟技能培訓、創業和社區影響，所有這些都整合在一個有凝聚力的品牌之下。
                我們的使命是通過引導青少年完成人生旅程的每個階段，幫助他們充分發揮潛能，
                並鼓勵他們回饋社會，從而對社會產生積極而持久的影響。</p>

            <p>一個以目標為導向的平台，致力於提升亞裔美國青年在社會中的影響力。
                我們創立 T.H.I.C.K. 的使命清晰而堅定：幫助美籍亞裔人士從大學教育開始，
                通過發展無形的軟性技能，晉升到更高的社會平台。
                THICK 致力於培養青少年擔任重要的決策職位，鼓勵他們成為企業主，
                為他人創造機會，最終回饋社會。</p>

            <p>透過專注於教育、領導才能和創業精神，我們預期 THICK 將成為改變的催化劑，
                確保未來世代的亞裔美國人具備領導、創新和以有意義的方式貢獻社區的能力。</p>

            <h3>我們的核心</h3>
            <p>Rachel Lam 致力於解決華裔美國人所面臨的挑戰，特別是在大學諮詢規劃、
                商業支持和社區參與等重要資源方面。她敏銳地察覺到這些不足，並立志彌補這些差距。
                Rachel 與三個非營利組織合作，組織大學規劃研討會，這些活動已直接影響超過200個亞裔家庭。
                在社區服務方面，Rachel 是Elmhurst United 非營利組織的一員，該組織致力於改善多元社區的生活質量、
                教育標準和安全。她也是大紐約地區華人同源會（CACAGNY）的創始成員之一，並目前擔任董事會成員。
                CACAGNY 曾參與美國最高法院的SFFA對哈佛大學和北卡羅來納大學的案件，支持挑戰基於種族的招生政策。
                作為SCORE的認證商業導師，Rachel 與美國小企業管理局（SBA）合作，幫助小企業應對挑戰並實現可持續增長。
                她也為工人階級社區提供免費的財務諮詢，專注於預算管理、消除信用卡債務以及建立六個月的應急基金。
                此外，Rachel 對青年教育做出了貢獻。她曾在紐約市警察局青年外展計劃中擔任助教，
                與Dr. Richard L. Glover 及其團隊合作，教授全面的四級財務管理課程。
                該課程為年輕人提供了重要的財務技能，幫助他們為未來做出明智的財務決策。
                Rachel 還積極參與Immigrant Hope的志願工作，這是一個為弱勢群體提供重要移民法律服務的非營利組織。
                在律師的指導下協助拉丁美洲青年獲得移民身份。她也曾與CUNY Citizenship Now合作，幫助低收入者申請美國公民身份。</p>

        </div>
    );
}

export default TestPage;