import React from "react";
import styles from 'styles/Services.module.css'
import classNames from "classnames";

function EssayAssistance() {
    return (
        <div className={styles.page}>
            <div className={styles.container}>

                <div className={styles.header}>
                    <h1>Essay Assistance</h1>
                </div>
                <div className={styles.content}>
                    <section className={styles.section}>
                        <h3 className={styles.motto}>Crafting Exceptional College Essays</h3>
                        <p>
                            College essays are a vital component of your application, providing a unique opportunity to showcase your personality, values, and passions beyond grades and test scores. A well-written essay can allow students to stand out from other applicants and make a difference in admissions decisions by highlighting your unique qualities and fit for the institution. Our team of expert advisors can help you write a compelling college essay and guide you through every step of the essay-writing process.
                        </p>
                    </section>
                    <div className={styles.banner}>
                        <h2>What we offer</h2>
                    </div>
                    <section className={styles.section}>
                        <h3>Guided Self-Discovery</h3>
                        <p>
                            Our team will assist you in uncovering your unique stories and strengths, ensuring that your essay reflects your genuine experiences and personal growth, making sure that your authentic self is expressed in your essay.
                        </p>
                    </section>
                    <section className={styles.section}>
                        <h3>Collaborative Process</h3>
                        <p>
                            We work closely with both you and your parents to ensure a thorough understanding of your background and goals, leading to a more impactful personal statement.
                        </p>
                    </section>
                    <section className={styles.section}>
                        <h3>Essay Brainstorming and Editing</h3>
                        <p>
                            We will assist in supporting you through every step of completing the Common Application, including brainstorming, drafting, and editing, for up to 20 schools.
                        </p>
                    </section>
                    <button className={classNames("btn", styles.btn)}>Get Started with Essay Assistance</button>
                </div>
            </div>
        </div>
    );
}

export default EssayAssistance;