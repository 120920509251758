import React from 'react';

function ThickLiaison() {
    return (
        <div>
            <h1><a href="https://www.thickliaison.org/">Thick Liaison</a></h1>
            <h3>
                We Help Asian-Americans Youth Ascend and Elevate into Leadership and Entrepreneurial Roles
            </h3>

            <p>
                Embark on your journey of Self-Transcendence
            </p>

            <p>
                Our carefully designed four-step journey empowers Asian-American youth, guiding them from essential academic and mindset development to leadership and entrepreneurial roles, ultimately fostering a spirit of giving back to the community
            </p>

            <p>
                THICK's 4-step journey direction is meticulously designed to address the holistic development of Asian-Americans youth.
            </p>

            <h4><a href="https://www.clearpillar.us/">ClearPillar</a></h4>
            <p>College Guidance</p>
            <p>
                Foundation for Success: Education is often the starting point for long-term success, especially in communities where credentials and academic achievements are highly valued. By focusing on helping Asian-Americans youth find and succeed in the right college, THICK lays the groundwork for future leadership roles. The journey begins with academic excellence because it equips individuals with knowledge, critical thinking, and networking opportunities that are essential for climbing the social and professional ladders.
            </p>

            <p>
                Empowering Informed Choices: Many Asian, especially the Chinese-American families may not have access to the resources or guidance needed to navigate the U.S. college admissions process. ClearPillar addresses this gap, ensuring that students are not just attending college but attending institutions that align with their strengths and aspirations.
            </p>

            <h4><a href="/">Ah Ha</a></h4>
            <p>Soft Skills Training</p>
            <p>
            Critical Skills for Leadership: While academic success is important, leadership requires more than just knowledge. Soft skills—such as communication, emotional intelligence, and critical thinking—are vital for leadership positions. By focusing on developing these skills, THICK ensures that its participants are not only capable but also effective in leading others and making decisions.
            </p>
            <p>Cultural Bridging: In the context of Asian-Americans, especially Chinese-Americans youth, soft skills training is particularly important because it helps bridge cultural differences that may otherwise hinder personal and professional growth. Developing these skills allows individuals to navigate different social and cultural environments more effectively.
            </p>

            <h4><a href="/">LuluMind</a></h4>
            <p>Entrepreneurship</p>

            <h4><a href="https://www.jaxconnect.org/">JaxConnect</a></h4>
            <p>Giving Back to the Community</p>

        </div>
    );
}

export default ThickLiaison;