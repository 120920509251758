import React from 'react';
import styles from "styles/CommunityImpact.module.css"

export default function CommunityImpact() {
    return (
        <div className={styles.page}>
            <div className={styles.container}>
                {/* <section className={styles.hero}>
                    <h1>Our Impact</h1>
                </section> */}

                <section className={styles.section}>
                    <h2>Community Impact</h2>
                    <p>
                        At ClearPillar, we are committed to transforming lives through education. 
                        Our mission extends beyond providing guidance to students and their families 
                        during the college admissions process; we are dedicated to creating lasting 
                        change in our communities by offering vital educational services.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2>Reserve Fund Contribution</h2>
                    <p>
                        For every student we assist, 7% of our proceeds will be allocated to a 
                        reserve fund that supports students who need financial aid. 
                        Additionally, 3% will be directed to <a href='https://www.jaxconnect.org/'>JaxConnect</a> to further their 
                        impactful work in helping youth with special needs.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2>ClearPillar's Impact</h2>
                    <div>
                        <p>
                            <strong>Empowering Students and Families:</strong> 
                            We provide personalized college advisory services 
                            that help students and families navigate the college 
                            admission process effectively, leading to better college 
                            choices and improved academic outcomes.
                        </p>
                    </div>
                    <div>
                        <p>
                            <strong>Supporting Educational Growth:</strong> 
                            We offer resources and opportunities that enhance 
                            students' academic and professional skills, 
                            fostering a culture of continuous learning and personal development.
                        </p>
                    </div>
                    <div>
                        <p>
                            <strong>Building Strong Communities:</strong> 
                            Our platform connects students, families, and advisors, 
                            creating a supportive community that encourages 
                            collaboration and mutual support for academic and personal growth.
                        </p>
                    </div>
                </section>

                <section className={styles.section}>
                    <h2>Connection to THICK’s Vision and Mission</h2>
                    <p>
                        ClearPillar is a vital part of the broader mission of 
                        Thick Liaison LLC. THICK is dedicated to shaping youth 
                        by providing suitable college options, soft skills training, 
                        financial literacy, practical business experience in entrepreneurship, 
                        and additionally, supporting special needs youth.
                    </p>
                    <p>
                        ClearPillar is proud to empower students and families in their 
                        educational journeys. We are particularly committed to making a 
                        difference in the lives of special needs youth, helping them 
                        realize their full potential and giving back to the community. 
                        To learn more about how we are making a global impact through THICK.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2>FAQs</h2>
                    <p>To be determined</p>
                </section>
            </div>
        </div>
    );
}
