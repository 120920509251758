import React from 'react';
import qrCH from 'images/qr_ch.JPG';
import qrEng from 'images/qr_eng.JPG';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { i18n } = useTranslation('home');
  const showQR = () => {
    if (i18n.language === "zh") {
      return (
        <div>
          <img src={qrCH} alt="Chinese QR Code" width="100" height="100"></img>
        </div>
      )
    } else {
      return (
        <div>
          <img src={qrEng} alt="English QR Code" width="100" height="100"></img>
        </div>
      )
    }
  }

  return (
    <footer className="footer py-4 mt-auto">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>ClearPillar</h5>
            <p>Supporting Your Path to Higher Education.</p>
          </div>
          <div className="col-md-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/">Home</a></li>
              <li><a href="/about-us">About</a></li>
              <li><a href="/services/admissions-strategy">Services</a></li>
              <li><a href="/opportunities/business-model">Join Team</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Contact Us</h5>
            <p>Email: info@clearpillar.us</p>
            <p>Phone: +1 (347) 400-4166</p>
            <br />
            <div className="qr-codes">
              <p>Scan our QR code to connect with us!</p>
              {showQR()}
            </div>
            <h5>Social Media</h5>
            <div className="social-media-icons">
              <a href="https://www.linkedin.com/company/thickliaison" target="_blank" className="me-3">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="/" target="_blank" className="me-3">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="/" target="_blank" className="me-3">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="/" target="_blank" className="me-3">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="text-center mt-3">
          <p>&copy; 2024 ClearPillar. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
