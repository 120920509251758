import React from 'react';

function BusinessModel() {
    return (
        <div>
            <h1 style={{color: "white"}}>Business Model</h1>
            <p style={{color: "white"}}>Empowering Chinese-American youth to rise and thrive in leadership and entrepreneurial roles. 
                College is not the only path in life. However, we believe that a strong education helps build a stronger 
                foundation for success and one of our steps in realizing this vision. At ClearPillar, we are committed to 
                fostering academic excellence and creating pathways for future leaders and innovators.
            </p>

            <h2>Target Audience</h2>
            <p><b>Students and Parents</b> seeking guidance through the complexities of college 
                admissions, financial aid, and academic planning.
            </p>

            <h2>Services for Students and Parents</h2>
            <p>
                <ul>
                    <li>
                        Admission Strategy and College List Building - Tailored strategies for 
                        building a personalized college list and planning applications.
                    </li>

                    <li>
                        Award Letters Comparison - Helping families compare financial aid 
                        offers to make informed decisions.
                    </li>

                    <li>
                        Common App and Application Review - Comprehensive 
                        review of applications to ensure they are complete and 
                        aligned with each school’s standards.
                    </li>

                    <li>
                        Essay Support - In-depth support for crafting impactful essays, 
                        including Common Application and supplemental essays.
                    </li>

                    <li>Extracurricular Planning - Support in building and 
                        highlighting extracurriculars.
                    </li>

                    <li>
                        FAFSA and CSS Form Submission - Expert assistance with 
                        filing FAFSA and CSS Profile forms accurately.
                    </li>

                    <li>
                        Financial Aid and Appeal Letters - Guidance in understanding 
                        financial aid packages and writing appeal letters if necessary.
                    </li>

                    <li>
                        Resume Editing  - Support in building a strong resume.
                    </li>

                    <li>
                        SAT/ACT Tutoring - Focused tutoring services to help students 
                        improve standardized test scores.
                    </li>    

                    <li>
                        Scholarship - Assistance in identifying scholarship opportunities.

                    </li>

                    <li>
                        Tuition Planning - Creating manageable financial plans for college 
                        tuition and expenses.
                    </li>                                                         
                </ul>
            </p>

            <h2>Core Players: Liaisons</h2>
            <p>
                ClearPillar functions as a comprehensive support system for students and their families, 
                offering a wide range of services in the college admissions process. Acting as a central 
                liaison, ClearPillar connects students and parents with specialists across key areas as such:
            </p>
            <h3>Team Expertise and Specializations:</h3>
            <ol>
                <li>Essay and Storytelling Specialist - Expert in developing essay content that tells each student's unique story in a way that resonates with admissions officers.</li>
                <li>Admissions Strategist - Former admissions counselor providing insights on what colleges look for in applicants.</li>
                <li>Licensed Financial Professional - Certified public accountant (CPA) and financial advisor to assist with financial planning and aid navigation.</li>
                <li>Student Advisors - Professionals dedicated to guiding students through each step of the college admissions process.</li>
                <li>SAT/ACT Tutors - Specialized tutors who focus on preparing students for college entrance exams.</li>   
            </ol>

            <h1>Motto:</h1>
            <p><b>"How do you know what you don’t know?"</b> – At ClearPillar, we help students uncover hidden strengths and untapped potential, ensuring their applications reflect the best of who they are.</p>
        </div>
    );
}

export default BusinessModel;