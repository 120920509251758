import React from 'react';
import { Link } from 'react-router-dom';

function InterestSuccess() {
  return (
    <div className="container mt-5">
      <h2>Thank you for submitting your interest!</h2>
      <p>We will contact you soon.</p>
      <Link to="/" className="btn btn-primary">Go Back to Home</Link>
    </div>
  );
}

export default InterestSuccess;
