import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MaintenanceBanner from 'components/MaintenanceBanner';
import { useAuth } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import styles from 'styles/Navbar.module.css'
import classNames from 'classnames';

export default function Navbar() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log(i18n.language);
  };

  return (
    <div className='bar'>
      <MaintenanceBanner />
      <nav className={`navbar navbar-expand-lg fixed-top`}>
        <div className="container-fluid">
          <Link className="navbar-brand" to="/"> <h2> ClearPillar 正知</h2> </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

              {/* Thick Liaison Page */}
              <div>
                <li className="nav-item">
                  <Link className="nav-link" to="/thick-liaison">Thick Liaison</Link>
                </li>
              </div>

              {/* Language Change */}
              <div className={styles.language}>
                <li className="nav-item">
                  <span className="nav-link" role="button" aria-expanded="false" onClick={() => changeLanguage('zh')}>中</span>
                </li>
                <li className="nav-item ">
                  <span className="nav-link me-1 ms-1">|</span>
                </li>
                <li className="nav-item me-3">
                  <span className="nav-link" role="button" aria-expanded="false" onClick={() => changeLanguage('en')}>EN</span>
                </li>
              </div>

              {/* About */}
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/about-us">About Us</Link></li>
                  <li><Link className="dropdown-item" to="/community-impact">Community Impact</Link></li>
                  <li><Link className="dropdown-item" to="/privacy">Privacy</Link></li>

                </ul>
              </li>

              {/* Services */}
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/services/admissions-strategy">Admissions Strategy</Link></li>
                  <li><Link className="dropdown-item" to="/services/essay-assistance">Essay Assistance</Link></li>
                  <li><Link className="dropdown-item" to="/services/tuition-planning">Tuition Planning</Link></li>
                  {/* <li><Link className="dropdown-item" to="/services/other-services">Other Services</Link></li> */}
                </ul>
              </li>

              {/* Resources */}
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Resources
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/resources/roadmap">Roadmap</Link></li>
                  <li><Link className="dropdown-item" to="/resources/essay-brainstorming">Essay Brainstorm</Link></li>
                  <li><Link className="dropdown-item" to="/resources/workshops">Workshops</Link></li>
                </ul>
              </li>

              {/* Join the Team */}
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Join the Team
                </Link>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/opportunities/business-model">Business Model</Link></li>
                  <li><Link className="dropdown-item" to="/opportunities/join-liaison">Join Team as Liaison</Link></li>
                  <li><Link className="dropdown-item" to="/opportunities/join-strategist">Join Team as Strategist</Link></li>
                  <li><Link className="dropdown-item" to="/opportunities/join-specialist">Join Team as Specialist</Link></li>
                  <li><Link className="dropdown-item" to="/opportunities/join-student-advisor">Join Team as Student Advisor</Link></li>
                  {/* <li><Link className="dropdown-item" to="/opportunities/join-career-advisor">Volunteer as Career Advisor</Link></li> */}
                </ul>
              </li>

              {/* <LoginRouting /> */}

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

// Checks if there is a user authenticated, in which it will replace the Login/Register tabs with the Profile tab
// function LoginRouting() {
//   const { isAuthenticated, logout } = useAuth();
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     try {
//       logout()
//       navigate('/')
//     } catch (error) {
//       console.log("Failed to logout.")
//     }
//   }

//   return isAuthenticated ?
//     // img src is placeholder profile picture -- should be replaced with the profile associated with the retrieve user 
//     <li className="nav-item dropdown">
//       <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//         <img src="https://reactjs.org/logo-og.png" style={{ height: "35px", width: "35px" }} className="rounded-circle" alt="..." />
//       </Link>
//       <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
//         <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
//         <li><Link className="dropdown-item" to="/settings">Settings</Link></li>
//         <hr className="nav-line"></hr>
//         <li><Link className="dropdown-item" to="/" onClick={handleLogout}>Logout</Link></li>
//       </ul>
//     </li>

//     :

//     <div>
//       {/* Login */}
//       <Link to="/login" role="button" aria-expanded="false">
//         <button className={classNames(styles.btn, "login")} type="button">Log In</button>
//       </Link>

//       {/* Register */}
//       <div className="btn-group">
//         <button type="button" className={classNames("dropdown-toggle", styles.btn)} data-bs-toggle="dropdown" aria-expanded="false">
//           Register
//         </button>
//         <ul className="dropdown-menu dropdown-menu-end">
//           {/* <li><Link className="dropdown-item" to="/register/student-register" role="button" aria-expanded="false">Student</Link></li>
//           <hr className="nav-line"></hr> */}
//           <li><Link className="dropdown-item" to="/register/strategist-register" role="button" aria-expanded="false">Strategist</Link></li>
//           <li><Link className="dropdown-item" to="/register/liaison-register" role="button" aria-expanded="false">Liaison</Link></li>
//           <li><Link className="dropdown-item" to="/register/studentadvisor-register" role="button" aria-expanded="false">Student Advisor</Link></li>
//         </ul>
//       </div>
//     </div>
//     ;
// }
