import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function InterestForm() {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phonenumber: '',
    services: [],
    message: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (service) => {
    setFormData((prevData) => ({
      ...prevData,
      services: prevData.services.includes(service)
        ? prevData.services.filter((s) => s !== service)
        : [...prevData.services, service],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:3001/api/interest-form', formData);
      if (response.status === 200) {
        // setSuccessMessage('Your interest has been submitted successfully! We will contact you soon.');
        // setErrorMessage('');
        // // Reset form data
        // setFormData({
        //   fullname: '',
        //   email: '',
        //   phonenumber: '',
        //   services: [],
        //   message: ''
        // });
        navigate('/interest-form-success');
      }
    } catch (error) {
      setErrorMessage('There was an error submitting the form. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Submit Your Interest</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fullname">Full Name:</label>
          <input
            type="text"
            id="fullname"
            name="fullname"
            value={formData.fullname}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phonenumber">Phone Number:</label>
          <input
            type="text"
            id="phonenumber"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <h4>Services</h4>
          <div>
            <input
              type="checkbox"
              id="tuitionplanning"
              checked={formData.services.includes('Tuition Planning')}
              onChange={() => handleCheckboxChange('Tuition Planning')}
            />
            <label htmlFor="tuitionplanning">Tution Planning</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="essayBrainstorming"
              checked={formData.services.includes('Essay Brainstorming')}
              onChange={() => handleCheckboxChange('Essay Brainstorming')}
            />
            <label htmlFor="essayBrainstorming">Essay Brainstorming</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="essayEditing"
              checked={formData.services.includes('Essay Editing')}
              onChange={() => handleCheckboxChange('Essay Editing')}
            />
            <label htmlFor="essayEditing">Essay Editing</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="extracurriculars"
              checked={formData.services.includes('Extracurriculars')}
              onChange={() => handleCheckboxChange('Extracurriculars')}
            />
            <label htmlFor="extracurriculars">Extracurriculars</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="collegeListCreation"
              checked={formData.services.includes('College List Creation')}
              onChange={() => handleCheckboxChange('College List Creation')}
            />
            <label htmlFor="collegeListCreation">College List Creation</label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="message">Additional Message (Optional):</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="form-control"
            rows="4"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
}

export default InterestForm;
