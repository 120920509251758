import React from 'react';
import styles from 'styles/Roadmap.module.css'

export default function Timeline() {
    return (
        <section className={styles.section}>
            <div className={styles["header-container"]}>
                <h1>College Planning Roadmap</h1>
                <br />
                <h4>Navigate Your Future: Milestones for Every Grade Level</h4>
            </div>
            <div className={styles.container}>
                <div className={styles.item}>
                    <div className={styles.grade}><h4>Grade 8</h4></div>
                    <div className={styles.star}><i className="fa-solid fa-star"></i></div>
                    <div className={styles.checklist}>
                        <ul>
                            <p>Academic Foundation</p>
                            <li>Develop strong study habits and time management skills. We provide various resources and organizations to help you build the right academic direction based on your interests.</li>
                            <p>Extracurriculars</p>
                            <li>Explore extracurricular activities. We’ll guide you on how to join clubs or organizations that match your interests.</li>
                            <p>High Schools List & Specialized High Schools/SHSAT Exam</p>
                            <li>Start researching and listing potential high schools, including specialized high schools if applicable (e.g., SHSAT prep tips).</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.grade}><h4>Grade 9-10</h4></div>
                    <div className={styles.star}><i className="fa-solid fa-star"></i></div>
                    <div className={styles.checklist}>
                        <ul>
                            <p>Financial Aid Preview</p>
                            <li>Get an early preview of financial aid opportunities by evaluating your family’s financial situation. This helps maximize FAFSA and scholarship opportunities.</li>
                            <p>Academic Development</p>
                            <li>Strive for excellence in your grades and challenge yourself with more advanced courses.</li>
                            <p>Extracurricular Activities</p>
                            <li>Deepen your participation in clubs, sports, or volunteer activities that align with your career goals. </li>
                            <p>PSAT Preparation</p>
                            <li>Begin preparing for the PSAT and other standardized tests. </li>
                            <p>Career Exploration</p>
                            <li>Start exploring potential career paths and related majors. </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.grade}><h4>Grade 11-12</h4></div>
                    <div className={styles.star}><i className="fa-solid fa-star"></i></div>
                    <div className={styles.checklist}>
                        <ul>
                            <p>Resume Building & Recommendation Letters</p>
                            <li>Build a strong resume by compiling your academic achievements, extracurriculars, and leadership roles. Start requesting recommendation letters early.</li>
                            <p>College Research & List</p>
                            <li>Research and build a balanced college list of reach, target, and safety schools. Think about tuition affordability and financial aid when creating your list.</li>
                            <p>SAT/ACT Prep</p>
                            <li>Prepare for the SAT/ACT to strengthen your college applications. </li>
                            <p>Application Prep</p>
                            <li>Get ready to complete college applications and write your college essays, including the Common App. </li>
                            <p>Financial Aid for Tuition (FAFSA/CSS)</p>
                            <li>Complete the FAFSA and/or CSS Profile for financial aid. Learn how to compare financial aid offers and maximize your award.</li>                            
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
