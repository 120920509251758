// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterStrategist_specialization-container__6DrLP {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.RegisterStrategist_specialization-box__8j\\+7a {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 10px;
  border: 1px solid #0d6efd;
}

.RegisterStrategist_specialization-box__8j\\+7a.RegisterStrategist_selected__glpAJ {
  border-color: #007bff;
  background-color: #a7c3f2;
}

.RegisterStrategist_accordion-container__9Lr1y {
  width: 100%;
  padding: 0;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/RegisterStrategist.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,oDAAoD;EACpD,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,UAAU;EACV,SAAS;AACX","sourcesContent":[".specialization-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n.specialization-box {\n  display: flex;\n  align-items: center;\n  padding: 0.5rem 1rem;\n  cursor: pointer;\n  transition: background-color 0.3s, border-color 0.3s;\n  border-radius: 10px;\n  border: 1px solid #0d6efd;\n}\n\n.specialization-box.selected {\n  border-color: #007bff;\n  background-color: #a7c3f2;\n}\n\n.accordion-container {\n  width: 100%;\n  padding: 0;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"specialization-container": `RegisterStrategist_specialization-container__6DrLP`,
	"specialization-box": `RegisterStrategist_specialization-box__8j+7a`,
	"selected": `RegisterStrategist_selected__glpAJ`,
	"accordion-container": `RegisterStrategist_accordion-container__9Lr1y`
};
export default ___CSS_LOADER_EXPORT___;
