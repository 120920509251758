import React from "react";
import styles from 'styles/Services.module.css'
import stylesAdmission from 'styles/ServicesAdmissions.module.css'
import classNames from "classnames";

function AdmissionStrategy() {
    return (
        <div>
            {/* admission strategy */}
            <div className={styles.page}>
                <div className={styles.container}>

                    <div className={styles.header} >
                        <h1>Admission Strategy</h1>
                    </div>
                    <div className={styles.content}>
                        <section className={styles.section}>
                            <h3 className={styles.motto}>Unlock the Secrets to Your Dream School</h3>
                            <p>
                                At ClearPillar, we understand that navigating the college admissions process can be overwhelming. That’s why our Admissions Strategy service connects you with seasoned professionals who have firsthand experience and insider knowledge of the schools you’re interested in.
                            </p>
                        </section>
                        <div className={styles.banner}>
                            <h2>What we offer</h2>
                        </div>
                        <section className={styles.section}>
                            <h3>Personalized Advice from Insiders</h3>
                            <p>
                                Our network includes former admission counselors and experts with direct connections to the schools you’re aiming for. These insiders offer you a unique perspective and valuable insights that go beyond generic advice. They know the nuances of each institution and can guide you through the application process with strategies tailored specifically to your target schools.
                            </p>
                            <p>
                                Our liaisons handle the crucial task of matching you with the perfect advisor based on your unique needs and goals.They ensure that you are paired with someone who has the right connections and expertise to give you the edge you need.
                            </p>
                        </section>

                        <div className={stylesAdmission.notepad}>
                            <h4>Insider Knowledge</h4>
                            <p>Gain access to advice from professionals who have been on the other side of the admissions desk and have established relationships with the schools you're targeting.</p>
                            <h4>Tailored Guidance</h4>
                            <p>Receive personalized strategies and insights that are specifically designed to enhance your application based on insider knowledge.</p>
                            <h4>Efficient Matching</h4>
                            <p>Our liaisons handle the detailed work of finding and connecting you with the advisor who best fits your needs, so you can focus on preparing your best application.</p>
                        </div>

                        <button className={classNames("btn", styles.btn)}>Get Started with Essay Assistance</button>
                    </div>
                </div>

                {/* college planning] */}
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1>College Planning</h1>
                    </div>
                    <div className={styles.content}>
                        <section className={styles.section}>
                            <h3 className={styles.motto}>Strategic Planning for Your Academic Future</h3>
                            <p>
                                Planning ahead for college is essential for preparing for a successful college application process and help you make important decisions for their future. It invovles understanding your interests, selecting the right courses, and building a strong extracirricular profile. Effective planning will help you navigate the college admissions process with confidence and sets you up for long term success. Our team will guide you through every step and create a personalized plan to ensure you are well prepared for college.
                            </p>
                        </section>
                        <div className={styles.banner}>
                            <h2>What we offer</h2>
                        </div>

                        <div className={styles.grid}>
                            <div className={styles.card}>
                                <h4>Interest Exploration</h4>
                                <p>Our advisors will assist you in exploring your interests and passions, helping you choose academic and extracurricular activities that align with your goals.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Academic Advising and Course Selection</h4>
                                <p>We provide personalized academic advising to help you select courses that align with your interests and future college requirements, ensuring you stay on track for success.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Extracurricular Profile Development</h4>
                                <p>Our team helps with developing a well-rounded extracurricular profile for you, highlighting your achievements and involvement in activities that enrich your college application.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Summer Activity Planning</h4>
                                <p>We assist with planning meaningful summer activities that align with your interests and academic goals, helping you gain valuable experiences for college applications.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>External Academic Competitions</h4>
                                <p>Our advisors provide guidance in identifying and preparing for relevant academic competitions to help you stand out and showcase your skills and knowledge.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>College Visits</h4>
                                <p>We help plan and optimize college visits, offering insights on what to look for and how to evaluate potential schools to make informed decisions for developing your college list.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Interest Definition and Goal Setting</h4>
                                <p>Exploring the student’s passions, defining clear academic objectives, and identifying potential university majors and career paths.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Letter of Recommendation Strategy</h4>
                                <p>Guidance on selecting the right recommenders to strengthen the student’s application.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>College List Creation</h4>
                                <p>Our advisors will collaborate with the student and family to finalize a list of colleges to apply for.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Extracurricular Activities List & Résumé Development</h4>
                                <p>We will assist in building the student’s resume with target extracurricular activities.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Final Application Review</h4>
                                <p>Thoroughly reviewing applications to ensure the student’s best chance at acceptance.</p>
                            </div>
                            <div className={styles.card}>
                                <h4>Decision Support</h4>
                                <p>Assisting students in making final decisions once college acceptance letters are received.</p>
                            </div>
                        </div>

                        <button className={classNames("btn", styles.btn, "mt-4")}>Get Started with College Planning</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdmissionStrategy;