// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThickLiaison_hero__DNMIj {
    margin-top: 3rem;
    text-align: center;
}

.ThickLiaison_container__sRa0s {
    max-width: 80%;
    margin: auto;
}

.ThickLiaison_clearpillar__3uMZN {
    background-color: purple;
}

.ThickLiaison_jaxconnect__7o4Jj {
    background-color: blue;
}

.ThickLiaison_ahha__6iAEp {
    background-color: red;
}

.ThickLiaison_lulumind__EoZDf {
    background-color: orange;
}`, "",{"version":3,"sources":["webpack://./src/styles/ThickLiaison.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".hero {\n    margin-top: 3rem;\n    text-align: center;\n}\n\n.container {\n    max-width: 80%;\n    margin: auto;\n}\n\n.clearpillar {\n    background-color: purple;\n}\n\n.jaxconnect {\n    background-color: blue;\n}\n\n.ahha {\n    background-color: red;\n}\n\n.lulumind {\n    background-color: orange;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `ThickLiaison_hero__DNMIj`,
	"container": `ThickLiaison_container__sRa0s`,
	"clearpillar": `ThickLiaison_clearpillar__3uMZN`,
	"jaxconnect": `ThickLiaison_jaxconnect__7o4Jj`,
	"ahha": `ThickLiaison_ahha__6iAEp`,
	"lulumind": `ThickLiaison_lulumind__EoZDf`
};
export default ___CSS_LOADER_EXPORT___;
