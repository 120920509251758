const schools = [
  { name: 'Amherst College', logo: '/college-logos/amherst college.png', link: 'https://www.amherst.edu' },
  { name: 'Barnard College', logo: '/college-logos/Barnard College.jpg', link: 'https://barnard.edu' },
  { name: 'Baruch College CUNY', logo: '/college-logos/Baruch College.png', link: 'https://baruch.cuny.edu' },
  { name: 'Binghamton University SUNY', logo: '/college-logos/Binghamton University.png', link: 'https://binghamton.edu' },
  { name: 'Boston College', logo: '/college-logos/Boston College.png', link: 'https://bc.edu' },
  { name: 'Boston University', logo: '/college-logos/Boston University.png', link: 'https://bu.edu' },
  { name: 'Brandeis University', logo: '/college-logos/brandeis university.jpg', link: 'https://brandeis.edu' },
  { name: 'Brown University', logo: '/college-logos/Brown University.png', link: 'https://brown.edu' },
  { name: 'California Polytechnic State University, San Luis Obispo', logo: '/college-logos/calpoly.png', link: 'https://calpoly.edu' },
  { name: 'Carnegie Mellon University', logo: '/college-logos/Carnegie mellon university.jpg', link: 'https://cmu.edu' },
  { name: 'Case Western Reserve University', logo: '/college-logos/case western reserve university.png', link: 'https://case.edu' },
  { name: 'Claremont McKenna College', logo: '/college-logos/claremont mckenna college.jpg', link: 'https://cmc.edu' },
  { name: 'Columbia University', logo: '/college-logos/columbia university.jpg', link: 'https://columbia.edu' },
  { name: 'Cooper Union', logo: '/college-logos/cooper union.png', link: 'https://cooper.edu' },
  { name: 'Cornell University', logo: '/college-logos/cornell university.jpg', link: 'https://cornell.edu' },
  { name: 'Dartmouth College', logo: '/college-logos/dartmouth college.png', link: 'https://dartmouth.edu' },
  { name: 'Drexel University', logo: '/college-logos/drexel university.png', link: 'https://drexel.edu' },
  { name: 'Duke University', logo: '/college-logos/Duke University.png', link: 'https://duke.edu' },
  { name: 'Emory University', logo: '/college-logos/emory university.png', link: 'https://emory.edu' },
  { name: 'Fordham University', logo: '/college-logos/Fordham University.png', link: 'https://fordham.edu' },
  { name: 'Franklin W. Olin College of Engineering', logo: '/college-logos/franklin w olin.png', link: 'https://olin.edu' },
  { name: 'George Mason University', logo: '/college-logos/george mason university.png', link: 'https://gmu.edu' },
  { name: 'George Washington University', logo: '/college-logos/george washington university.png', link: 'https://gwu.edu' },
  { name: 'Georgetown University', logo: '/college-logos/Georgetown university.png', link: 'https://georgetown.edu' },
  { name: 'Georgia Institute of Technology', logo: '/college-logos/Georgia Institute of Technology.png', link: 'https://gatech.edu' },
  { name: 'Harvard University', logo: '/college-logos/harvard university.jpg', link: 'https://harvard.edu' },
  { name: 'Harvey Mudd College', logo: '/college-logos/Harvey Mudd College.png', link: 'https://hmc.edu' },
  { name: 'Hofstra University', logo: '/college-logos/hofstra university.png', link: 'https://hofstra.edu' },
  { name: 'Illinois Institute of Technology', logo: '/college-logos/illinois tech.png', link: 'https://iit.edu' },
  { name: 'Johns Hopkins University', logo: '/college-logos/john hopkins university.jpg', link: 'https://jhu.edu' },
  { name: 'MIT', logo: '/college-logos/mit.png', link: 'https://mit.edu' },
  { name: 'New York University', logo: '/college-logos/NYU.png', link: 'https://nyu.edu' },
  { name: 'Northeastern University', logo: '/college-logos/northeastern university.png', link: 'https://northeastern.edu' },
  { name: 'Northwestern University', logo: '/college-logos/northwestern university.jpg', link: 'https://northwestern.edu' },
  { name: 'Pepperdine University', logo: '/college-logos/pepperdine university.jpg', link: 'https://pepperdine.edu' },
  { name: 'Pomona College', logo: '/college-logos/pomons college.jpg', link: 'https://pomona.edu' },
  { name: 'Princeton University', logo: '/college-logos/princeton university.png', link: 'https://princeton.edu' },
  { name: 'Purdue University - West Lafayette', logo: '/college-logos/Purdue university.png', link: 'https://purdue.edu' },
  { name: 'Rensselaer Polytechnic Institute', logo: '/college-logos/rpi.png', link: 'https://rpi.edu' },
  { name: 'Rhodes College', logo: '/college-logos/rhodes college.jpg', link: 'https://rhodes.edu' },
  { name: 'Rice University', logo: '/college-logos/rice university.jpg', link: 'https://rice.edu' },
  { name: 'Rochester Institute of Technology', logo: '/college-logos/rit.png', link: 'https://rit.edu' },
  { name: 'Rutgers University - New Brunswick', logo: '/college-logos/rutgers.png', link: 'https://rutgers.edu' },
  { name: 'San Diego State University', logo: '/college-logos/san diego state university.png', link: 'https://sdsu.edu' },
  { name: 'Santa Clara University', logo: '/college-logos/santa clara university.jpg', link: 'https://scu.edu' },
  { name: 'Stanford University', logo: '/college-logos/stanford university.png', link: 'https://stanford.edu' },
  { name: 'Stevens Institute of Technology', logo: '/college-logos/stevens institute of technology.jpg', link: 'https://stevens.edu' },
  { name: 'Stony Brook University SUNY', logo: '/college-logos/stony brook university.jpg', link: 'https://stonybrook.edu' },
  { name: 'Swarthmore College', logo: '/college-logos/swarthmore.png', link: 'https://swarthmore.edu' },
  { name: 'Syracuse University', logo: '/college-logos/syracuse.png', link: 'https://syracuse.edu' },
  { name: 'Temple University', logo: '/college-logos/temple university.png', link: 'https://temple.edu' },
  { name: 'Tufts University', logo: '/college-logos/tufts university.jpg', link: 'https://tufts.edu' },
  { name: 'US Military Academy', logo: '/college-logos/us military academy.png', link: 'https://westpoint.edu' },
  { name: 'University at Buffalo SUNY', logo: '/college-logos/ubuff.png', link: 'https://buffalo.edu' },
  { name: 'UC Berkeley', logo: '/college-logos/uc berkeley.jpeg', link: 'https://berkeley.edu' },
  { name: 'UC Davis', logo: '/college-logos/uc davis.png', link: 'https://ucdavis.edu' },
  { name: 'UC Irvine', logo: '/college-logos/uc irvine.jpg', link: 'https://uci.edu' },
  { name: 'UC Los Angeles', logo: '/college-logos/uc las angeles.png', link: 'https://ucla.edu' },
  { name: 'UC Riverside', logo: '/college-logos/uc riverside.png', link: 'https://ucr.edu' },
  { name: 'UC San Diego', logo: '/college-logos/uc san diego.png', link: 'https://ucsd.edu' },
  { name: 'UC Santa Barbara', logo: '/college-logos/uc santa barbara.jpg', link: 'https://ucsb.edu' },
  { name: 'UC Santa Cruz', logo: '/college-logos/uc santa cruz.jpg', link: 'https://ucsc.edu' },
  { name: 'University of Chicago', logo: '/college-logos/University of Chicago.png', link: 'https://uchicago.edu' },
  { name: 'University of Illinois at Chicago', logo: '/college-logos/University of Illinois Chicago.png', link: 'https://uic.edu' },
  { name: 'University of Illinois at Urbana-Champaign', logo: '/college-logos/University of Illinois urbana champaign.png', link: 'https://illinois.edu' },
  { name: 'University of Maryland, College Park', logo: '/college-logos/University of Maryland.png', link: 'https://umd.edu' },
  { name: 'University of Michigan', logo: '/college-logos/umich.png', link: 'https://umich.edu' },
  { name: 'University of North Carolina at Chapel Hill', logo: '/college-logos/university of north carolina at chapel hill.png', link: 'https://unc.edu' },
  { name: 'University of Notre Dame', logo: '/college-logos/university of notre dame.jpg', link: 'https://nd.edu' },
  { name: 'University of Pennsylvania', logo: '/college-logos/upenn.png', link: 'https://upenn.edu' },
  { name: 'University of Pittsburgh - Pittsburgh Campus', logo: '/college-logos/University of Pittsburgh.png', link: 'https://pitt.edu' },
  { name: 'University of Rochester', logo: '/college-logos/uroch.png', link: 'https://rochester.edu' },
  { name: 'University of Southern California', logo: '/college-logos/University of Southern California.jpg', link: 'https://usc.edu' },
  { name: 'The University of Texas at Austin', logo: '/college-logos/utexas at austin.png', link: 'https://utexas.edu' },
  { name: 'University of Virginia', logo: '/college-logos/University of Virginia.jpg', link: 'https://virginia.edu' },
  { name: 'University of Washington - Seattle', logo: '/college-logos/University of Washington.jpg', link: 'https://washington.edu' },
  { name: 'Vanderbilt University', logo: '/college-logos/vanderbilt university.png', link: 'https://vanderbilt.edu' },
  { name: 'Villanova University', logo: '/college-logos/Villanova University.jpg', link: 'https://villanova.edu' },
  { name: 'Virginia Tech', logo: '/college-logos/virginia tech.png', link: 'https://vt.edu' },
  { name: 'Washington University in St. Louis', logo: '/college-logos/washington university in st louis.png', link: 'https://wustl.edu' },
  { name: 'Wellesley College', logo: '/college-logos/wellesley.jpg', link: 'https://wellesley.edu' },
  { name: 'Yale University', logo: '/college-logos/yale.png', link: 'https://yale.edu' },
];

export default schools;