import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import dateFormat from 'utils/dateFormat'


function AllMeetings() {
    const location = useLocation();
    //const { m } = location.state || {};
    const [meetings, setMeetings] = useState([]);

    // useEffect(() => {
    //     setMeetings(m);
    //     console.log(m)
    // }, [m])

    useEffect(() => {
        const fetchMeetings = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get('http://localhost:3001/api/meetings', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setMeetings(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchMeetings();
    }, []);

    const allMeetings = meetings.map((m) => {
        return (
            <tr key={m.id}>
                <td>{dateFormat(m.meeting_date)}</td>
                <td>{m.student_id}</td>
            </tr>
        )
    })

    return (
        <div>
            <h1>All Meetings</h1>
            {/* Here you would render the list of all meetings */}
            <p>List of all meetings goes here...</p>

            <div >
                <div>
                    <h3>Upcoming Meetings</h3>
                    {/* <button>All meetings</button> */}
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Student</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allMeetings}
                        </tbody>
                    </Table>


                </div>
            </div>
        </div>
    );
}

export default AllMeetings;
