// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_page__OsVdS {
    background: linear-gradient(0deg, rgba(15, 6, 42, 1) 0%, rgba(94, 62, 182, 1) 30%, rgba(94, 62, 182, 1) 60%, rgba(15, 6, 42, 1) 100%);
}

.Login_container__0c7uz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;

}

.Login_container__0c7uz h1 {
    font-size: 2rem !important;
    color: white;
    margin-bottom: 20px;
}

.Login_form__kFnwI {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.Login_form__kFnwI label {
    display: block;
    margin-bottom: 20px;
    font-size: 1rem;
    color: #333;
}

.Login_input__SJBGF {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    font-size: 1rem;
}

.Login_input__SJBGF:focus {
    border-color: #333;
    outline: none;
}

.Login_btn__pALc8 {
    width: 100%;
    padding: 10px 20px;
}

/* .login-button {
    background: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
    width: 100%;
    margin-top: 10px;
}

.login-button:hover {
    background: #555;
} */`, "",{"version":3,"sources":["webpack://./src/styles/Login.module.css"],"names":[],"mappings":"AAAA;IACI,qIAAqI;AACzI;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;;AAEjB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;;;;;;;;;;;;;;;GAeG","sourcesContent":[".page {\n    background: linear-gradient(0deg, rgba(15, 6, 42, 1) 0%, rgba(94, 62, 182, 1) 30%, rgba(94, 62, 182, 1) 60%, rgba(15, 6, 42, 1) 100%);\n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    padding: 20px;\n\n}\n\n.container h1 {\n    font-size: 2rem !important;\n    color: white;\n    margin-bottom: 20px;\n}\n\n.form {\n    background: #fff;\n    border-radius: 10px;\n    padding: 20px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 400px;\n}\n\n.form label {\n    display: block;\n    margin-bottom: 20px;\n    font-size: 1rem;\n    color: #333;\n}\n\n.input {\n    width: 100%;\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n    margin-top: 5px;\n    font-size: 1rem;\n}\n\n.input:focus {\n    border-color: #333;\n    outline: none;\n}\n\n.btn {\n    width: 100%;\n    padding: 10px 20px;\n}\n\n/* .login-button {\n    background: #333;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background 0.3s;\n    width: 100%;\n    margin-top: 10px;\n}\n\n.login-button:hover {\n    background: #555;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Login_page__OsVdS`,
	"container": `Login_container__0c7uz`,
	"form": `Login_form__kFnwI`,
	"input": `Login_input__SJBGF`,
	"btn": `Login_btn__pALc8`
};
export default ___CSS_LOADER_EXPORT___;
