// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_box__ghJNk {
    display: flex;
    margin: 2%;
}

.Dashboard_col__t1ktv {
    display: block;
    width: 50%;
    height: 100%;
    margin-right: 2%;
    margin-left: 1%;
}

table {
    table-layout: fixed;
    width: 100%;
}

td.Dashboard_profile__CzfA9:hover {
    background-color: lightgray;
}

.Dashboard_no_border__KCPxk {
    border-color: transparent !important;
}

.Dashboard_center__O0wsg {
    text-align: center;
}

.Dashboard_right__x1aO6 {
    text-align: right;
}

.Dashboard_red__qXoV- {
    color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,cAAc;IACd,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".box {\n    display: flex;\n    margin: 2%;\n}\n\n.col {\n    display: block;\n    width: 50%;\n    height: 100%;\n    margin-right: 2%;\n    margin-left: 1%;\n}\n\ntable {\n    table-layout: fixed;\n    width: 100%;\n}\n\ntd.profile:hover {\n    background-color: lightgray;\n}\n\n.no_border {\n    border-color: transparent !important;\n}\n\n.center {\n    text-align: center;\n}\n\n.right {\n    text-align: right;\n}\n\n.red {\n    color: red !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `Dashboard_box__ghJNk`,
	"col": `Dashboard_col__t1ktv`,
	"profile": `Dashboard_profile__CzfA9`,
	"no_border": `Dashboard_no_border__KCPxk`,
	"center": `Dashboard_center__O0wsg`,
	"right": `Dashboard_right__x1aO6`,
	"red": `Dashboard_red__qXoV-`
};
export default ___CSS_LOADER_EXPORT___;
