import React from 'react';
import { Link } from 'react-router-dom';
import strategistImage from 'images/gradstudent.jpeg';
import styles from 'styles/JoinTheTeam.module.css'
import classNames from 'classnames';

function JoinStrategist() {
    return (
        <div className={styles.page}>
            <div className={styles.section}>
                <div className={styles.content}>
                    <h2>Join ClearPillar as a Strategist</h2>
                    <p>At ClearPillar, we strive to provide top-notch college advisory services. Our Strategists play a crucial role in guiding students and families through the college admissions process with their expertise and insights.</p>

                    <div className={styles.info}>
                        <div className={styles.text}>
                            <h3>What is a Strategist?</h3>
                            <p>A Strategist is an experienced professional with a deep understanding of college admissions. Strategists provide personalized guidance to students, helping them navigate the complexities of applying to college.</p>
                        </div>
                        <div className={styles.image}>
                            <img src={strategistImage} alt="Strategist" />
                        </div>
                    </div>

                    <div className={styles.notepad}>
                        <h3>What Can You Offer as a Strategist?</h3>
                        <ul>
                            <li><strong>Personalized Guidance:</strong> Offer tailored advice based on each student's unique profile and goals.</li>
                            <li><strong>Application Strategies:</strong> Provide insights on effective application strategies and essay writing tips.</li>
                            <li><strong>Academic Planning:</strong> Assist with course selection, extracurricular activities, and summer planning.</li>
                            <li><strong>Interview Preparation:</strong> Help students prepare for college interviews with mock interviews and feedback.</li>
                            <li><strong>Financial Aid:</strong> Advise on financial aid options and scholarship opportunities.</li>
                        </ul>
                    </div>

                    <h3>How It Works</h3>
                    <ol>
                        <li><strong>Join Our Team:</strong> Apply to become a Strategist and join our team of experts.</li>
                        <li><strong>Get Matched:</strong> Be matched with students and families based on your expertise and their needs.</li>
                        <li><strong>Provide Guidance:</strong> Offer your insights and advice through one-on-one sessions.</li>
                        <li><strong>Earn Compensation:</strong> Get paid for your sessions and help students achieve their dreams.</li>
                    </ol>

                    <p>Our Strategists are dedicated professionals who are passionate about helping students succeed. If you have the expertise and the desire to make a difference, we invite you to join our team.</p>

                    <h3>Join Our Team as a Strategist</h3>

                    <h4>Why Become a Strategist?</h4>
                    <ul>
                        <li><strong>Make an Impact:</strong> Help students achieve their educational goals and make a lasting impact on their lives.</li>
                        <li><strong>Flexible Hours:</strong> Work on your schedule and take on as many clients as you can handle.</li>
                        <li><strong>Competitive Pay:</strong> Earn a competitive rate for your expertise and time.</li>
                        <li><strong>Professional Development:</strong> Enhance your skills and grow your professional network.</li>
                    </ul>

                    <p><strong>Apply today and become a part of our mission to empower students!</strong></p>

                    <div className={styles.container}>
                        <Link to="/register/strategist-register" className={classNames("btn", styles.btn)}>Apply to be a Strategist</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinStrategist;
