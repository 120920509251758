import { useState } from 'react';
import { Link } from 'react-router-dom';
import liaisonImage from 'images/gradstudent.jpeg';
import styles from 'styles/JoinTheTeam.module.css'
import classNames from 'classnames';

function JoinLiaison() {
    const [flipped, setFlipped] = useState({
        join1: false,
        join2: false,
        join3: false,
        join4: false
    });

    const handleFlip = (card) => {
        setFlipped({
            ...flipped,
            [card]: !flipped[card],
        });
    };

    return (
        <div className={styles.page}>
            <div className={styles.section}>
                <div className={styles.content}>
                    <p>
                        The Liaison role at ClearPillar is vital, serving as the bridge between students, parents, and professionals. Fluent in both languages and deeply understanding of both cultures, our liaisons facilitate clear communication and understanding. They ensure students and parents—often English language learners—are fully supported and understood at every step, bridging cultural and linguistic gaps with empathy and expertise.
                    </p>

                    <h2>Join ClearPillar as a Liaison – Make a Meaningful Impact in Education</h2>
                    <p>
                        At ClearPillar, our Liaisons are more than just coordinators; they are the cultural and linguistic bridge connecting students, families, and our team of admission experts. As a Liaison, you’ll play a crucial role in ensuring that students and their families receive the personalized guidance they need for college admissions success.
                    </p>

                    <div className={styles.info}>
                        <div className={styles.text}>
                            <h3>Who is a Liaison?</h3>
                            <p>
                                A Liaison at ClearPillar is a caring, dedicated professional who facilitates communication, manages relationships, and oversees smooth coordination between clients and strategists. This role is essential for bridging gaps and supporting families throughout their admissions journey, especially those for whom English is a second language.
                            </p>
                        </div>
                        {/* <div className={styles.image}>
                            <img src={liaisonImage} alt="Liaison" />
                        </div> */}
                    </div>

                    <div>
                        <h3>Why Join Us as a Liaison?</h3>
                        <div className="row">
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join1 ? styles.flipped : ''}`} onClick={() => handleFlip('join1')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Academic Strategy & College Planning</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Expertise in ensuring you excel in the college admissions landscape based on your target schools.</p>
                                            <p>Tailored admission techniques.</p>
                                            <p>Direct connection with former admissions officers and advisors.</p>
                                            <p>Personalized application planning.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join2 ? styles.flipped : ''}`} onClick={() => handleFlip('join2')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Essay Assistance</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Tailored guidance from brainstorming development and crafting your voice.</p>
                                            <p>Targeted support for supplemental essays tailored to specific schools.</p>
                                            <p>Enhance your essays with professional editors.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join3 ? styles.flipped : ''}`} onClick={() => handleFlip('join3')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Financial Aid and Tuition</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Optimize your financial aid eligibility with resources provided by certified financial professionals and aid officers.</p>
                                            <p>Plan with proper asset allocation to ensure you qualify for the most monetary support possible.</p>
                                            <p>Expert appeal process assistance is available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={`${styles["feature-box"]} ${flipped.join4 ? styles.flipped : ''}`} onClick={() => handleFlip('join4')}>
                                    <div className={styles["feature-box-inner"]}>
                                        <div className={styles["feature-box-front"]}>
                                            <h3>Financial Aid and Tuition</h3>
                                        </div>
                                        <div className={styles["feature-box-back"]}>
                                            <p>Optimize your financial aid eligibility with resources provided by certified financial professionals and aid officers.</p>
                                            <p>Plan with proper asset allocation to ensure you qualify for the most monetary support possible.</p>
                                            <p>Expert appeal process assistance is available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div>
                        <h3>Requirements</h3>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-sm">
                                    <h1>01</h1>
                                    <h3>Passion for Education</h3>
                                    <p>
                                        A deep commitment to helping students and families navigate their college journeys.
                                    </p>
                                </div>
                                <div className="col-sm">
                                    <h1>02</h1>
                                    <h3>Student-Centric Mindset</h3>
                                    <p>
                                        A genuine desire to support students and advocate for their success.
                                    </p>
                                </div>
                                <div className="col-sm">
                                    <h1>03</h1>
                                    <h3>Strong Communication Skills</h3>
                                    <p>
                                        Ability to foster clear, empathetic communication between clients and strategists.
                                    </p>
                                </div>
                                <div className="col-sm">
                                    <h1>04</h1>
                                    <h3>Bilingual Proficiency</h3>
                                    <p>
                                        Fluency in English and Chinese (Mandarin or Cantonese).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h3>Liaison Responsibilities</h3>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-sm">
                                    <h1>01</h1>
                                    <h3>Client Matching</h3>
                                    <p>
                                        Thoughtfully pair students and families with the most suitable strategists based on their profiles.
                                    </p>
                                </div>
                                <div className="col-sm">
                                    <h1>02</h1>
                                    <h3>Communication Facilitation</h3>
                                    <p>
                                        Bridge language and cultural gaps to ensure clear, effective communication between clients and strategists.
                                    </p>
                                </div>
                                <div className="col-sm">
                                    <h1>03</h1>
                                    <h3>Relationship Management</h3>
                                    <p>
                                        Build strong, trusting relationships with clients and strategists to enhance the admissions process.
                                    </p>
                                </div>
                                <div className="col-sm">
                                    <h1>04</h1>
                                    <h3>Support and Coordination</h3>
                                    <p>
                                        Provide ongoing support and seamless coordination, guiding clients every step of the way.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3>How It Works</h3>
                    <ol>
                        <li><strong>Apply:</strong> Submit your application to become a liaison at ClearPillar.</li>
                        <li><strong>Interview:</strong> Participate in an interview to discuss your skills and experience.</li>
                        <li><strong>Onboarding:</strong> Complete the onboarding process and start facilitating client-strategist interactions.</li>
                    </ol>

                    <h3>Ready to Join Our Team?</h3>
                    <p>
                        If you’re bilingual in English and Chinese, care deeply about education, and excel at connecting with students and families, ClearPillar needs you. Join us as a Liaison and make a real difference in students’ lives, guiding them toward college success with ClearPillar’s expert support.
                    </p>

                    <p><b>Apply today and be a part of a team that values cultural understanding, educational support, and student success.</b></p>

                    <div className={styles.container}>
                        <Link to="#" className={classNames("btn", styles.btn)}> Apply to be a Liaison </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinLiaison;
