// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Workshops_page__WzSpC {
  background: linear-gradient(0deg, rgba(15, 6, 42, 1) 0%, rgba(94, 62, 182, 1) 30%, rgba(94, 62, 182, 1) 60%, rgba(15, 6, 42, 1) 100%);
  color: white;
}

.Workshops_wrapper__SkpRp {
  padding: 50px;
  text-align: center;
}

.Workshops_wrapper__SkpRp h1 {
  font-family: Arial, sans-serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.Workshops_wrapper__SkpRp p {
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 20px;
}

.Workshops_tabs__ATTzd {
  font-weight: bold;
  color: black;
}

.Workshops_card__A26xf {
  margin: 3px;
}

.Workshops_card-header__6MnHB {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Workshops_card-header__6MnHB:hover {
  background-color: #83a2c3;
}

.Workshops_card-header__6MnHB i {
  transition: transform 0.3s, color 0.3s;
}

.Workshops_card-header__6MnHB:hover i {
  transform: scale(1.1);
  color: white;
}

.Workshops_card-header__6MnHB.Workshops_active__DTwMQ {
  background-color: #83a2c3;
  color: #fff;
}

.Workshops_card-header__6MnHB.Workshops_active__DTwMQ i {
  color: #fff;
}

.Workshops_card-body__iqVvj {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Workshops_card-body__iqVvj>* {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/styles/Workshops.module.css"],"names":[],"mappings":"AAAA;EACE,qIAAqI;EACrI,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,6CAA6C;EAC7C,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".page {\n  background: linear-gradient(0deg, rgba(15, 6, 42, 1) 0%, rgba(94, 62, 182, 1) 30%, rgba(94, 62, 182, 1) 60%, rgba(15, 6, 42, 1) 100%);\n  color: white;\n}\n\n.wrapper {\n  padding: 50px;\n  text-align: center;\n}\n\n.wrapper h1 {\n  font-family: Arial, sans-serif;\n  font-size: 36px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.wrapper p {\n  font-family: Arial, sans-serif;\n  font-size: 18px;\n  margin-bottom: 20px;\n}\n\n.tabs {\n  font-weight: bold;\n  color: black;\n}\n\n.card {\n  margin: 3px;\n}\n\n.card-header {\n  font-size: 16px;\n  font-weight: bold;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.card-header:hover {\n  background-color: #83a2c3;\n}\n\n.card-header i {\n  transition: transform 0.3s, color 0.3s;\n}\n\n.card-header:hover i {\n  transform: scale(1.1);\n  color: white;\n}\n\n.card-header.active {\n  background-color: #83a2c3;\n  color: #fff;\n}\n\n.card-header.active i {\n  color: #fff;\n}\n\n.card-body {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.card-body>* {\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Workshops_page__WzSpC`,
	"wrapper": `Workshops_wrapper__SkpRp`,
	"tabs": `Workshops_tabs__ATTzd`,
	"card": `Workshops_card__A26xf`,
	"card-header": `Workshops_card-header__6MnHB`,
	"active": `Workshops_active__DTwMQ`,
	"card-body": `Workshops_card-body__iqVvj`
};
export default ___CSS_LOADER_EXPORT___;
