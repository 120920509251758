import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HowItWorks from '@home/HowItWorks';
import CollegesAd from '@home/CollegesAd';
import { useTranslation } from 'react-i18next';
import styles from 'styles/Home.module.css'
import classNames from 'classnames';

export default function Home() {
  /* flipping cards */
  const [flipped, setFlipped] = useState({
    pillar1: false,
    pillar2: false,
    pillar3: false,
  });

  const handleFlip = (card) => {
    setFlipped({
      ...flipped,
      [card]: !flipped[card],
    });
  };

  const { t } = useTranslation();

  return (
    <div className="fullscreen-div">
      {/* Hero Header Section */}
      <section className={classNames(styles.hero, "py-5")}>
        <div className={styles.overlay}></div>
        <div className={classNames(styles.container, "container")}>
          <span className={styles["empower-title"]}>
            {t('home.title1')} {t('home.title2')}<br />{t('home.title3')}
          </span>
          <br />
          <br />
          <p className="lead mb-2">{t('home.description1')}</p>
          <p className="lead mb-4">{t('home.description2')}</p>
          {/* <Link to="/about-us" className={classNames(styles.btn, "btn", "mt-4")}>Learn More About Us</Link> */}
          <Link to="/student-interest-form" className={classNames(styles.btn, "btn", "mt-4")}>Get Started Today!</Link>
        </div>
      </section>

      {/* Experts Section */}
      <section className='text-center'>
        <div className={classNames(styles.container, "container")}>
          <h2>Your Education. Our Experts.</h2>
          <p>Get valuable advice from our team of expert college strategists.</p>
          <p>Our dedicated liaisons review your materials and match you with the best-fitting advisors
            who specialize in the services you need, ensuring personalized guidance every step of the way.</p>
        </div>
      </section>

      {/* Step 1, 2, 3 */}
      <div className="container text-center py-5">
        <div className="row">
          <div className="col">
            <h2>01</h2>
            <p>Tell us about yourself through our pillar form</p>
          </div>
          <div className="col">
            <h2>02</h2>
            <p>Our liaison reviews and matches you with a specialist</p>
          </div>
          <div className="col">
            <h2>03</h2>
            <p>We create a personalized academic plan</p>
          </div>
        </div>
      </div>

      {/* Our Pillars */}
      <section className={classNames("text-center", "py-5", styles.offers)}>
        <div className={classNames(styles.container, "container")}>
          <div className={styles.center}>
            <div className={styles["star-container"]}>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <h2>Our Pillars</h2>
              <div className={styles["star-items"]}>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
          </div>
          <p> <i> Click to learn more. </i></p>
          <div className="row">
            <div className="col-md-4">
              <div className={`${styles["feature-box"]} ${flipped.pillar1 ? styles.flipped : ''}`} onClick={() => handleFlip('pillar1')}>
                <div className={styles["feature-box-inner"]}>
                  <div className={styles["feature-box-front"]}>
                    <h3>Academic Strategy & College Planning</h3>
                  </div>
                  <div className={styles["feature-box-back"]}>
                    <p>Expertise in ensuring you excel in the college admissions landscape based on your target schools.</p>
                    <p>Tailored admission techniques.</p>
                    <p>Direct connection with former admissions officers and advisors.</p>
                    <p>Personalized application planning.</p>
                    <Link to="#">Click to learn more.</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles["feature-box"]} ${flipped.pillar2 ? styles.flipped : ''}`} onClick={() => handleFlip('pillar2')}>
                <div className={styles["feature-box-inner"]}>
                  <div className={styles["feature-box-front"]}>
                    <h3>Essay Assistance</h3>
                  </div>
                  <div className={styles["feature-box-back"]}>
                    <p>Tailored guidance from brainstorming development and crafting your voice.</p>
                    <p>Targeted support for supplemental essays tailored to specific schools.</p>
                    <p>Enhance your essays with professional editors.</p>
                    <Link to="#">Click to learn more.</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles["feature-box"]} ${flipped.pillar3 ? styles.flipped : ''}`} onClick={() => handleFlip('pillar3')}>
                <div className={styles["feature-box-inner"]}>
                  <div className={styles["feature-box-front"]}>
                    <h3>Financial Aid and Tuition</h3>
                  </div>
                  <div className={styles["feature-box-back"]}>
                    <p>Optimize your financial aid eligibility with resources provided by certified financial professionals and aid officers.</p>
                    <p>Plan with proper asset allocation to ensure you qualify for the most monetary support possible.</p>
                    <p>Expert appeal process assistance is available.</p>
                    <Link to="#">Click to learn more.</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={classNames("text-center", "py-5", styles.offers)}>
        <div className={classNames(styles.container, "container")}>
          <h2>Explore What We Offer</h2>
          <p> <i> Click to learn more. </i></p>
          <div className="row">
            <div className="col-md-4">
              <div className={`${styles["feature-box"]} ${flipped.expertGuidance ? styles.flipped : ''}`} onClick={() => handleFlip('expertGuidance')}>
                <div className={styles["feature-box-inner"]}>
                  <div className={styles["feature-box-front"]}>
                    <h3>Connect</h3>
                  </div>
                  <div className={styles["feature-box-back"]}>
                    <p>Think of us as your personalized guide to academic success. 
                      You'll have one-on-one assistance throughout your journey.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles["feature-box"]} ${flipped.tailoredPlans ? styles.flipped : ''}`} onClick={() => handleFlip('tailoredPlans')}>
                <div className={styles["feature-box-inner"]}>
                  <div className={styles["feature-box-front"]}>
                    <h3>Support</h3>
                  </div>
                  <div className={styles["feature-box-back"]}>
                    <p>Being bilingual can feel like another world.
                      We bridge communication gaps between students and parents in your 
                      preferred language while building your confidence in English expression. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles["feature-box"]} ${flipped.comprehensiveSupport ? styles.flipped : ''}`} onClick={() => handleFlip('comprehensiveSupport')}>
                <div className={styles["feature-box-inner"]}>
                  <div className={styles["feature-box-front"]}>
                    <h3>Commitment</h3>
                  </div>
                  <div className={styles["feature-box-back"]}>
                    <p> We keep parents informed about their child's
                      progress while respecting student privacy, ensuring
                      cultural sensitivity and involvement without compromising
                      personal stories and experiences.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Services */}
      {/* <section className={classNames(styles["unique-services-div"], "py-5")}>
        <div className={classNames(styles["unique-services-container"], "container")}>
          <div className={styles["flex-row"]}>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star "></i>
            <h2 className="me-4 ms-4">Our Pillars</h2>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
          </div>

          <hr className={styles.line} />

          <div className={classNames(styles["unique-service-section"], "row", "mt-5")}>
            <div className={classNames(styles.card1, "order-md-1")}>
              <Link to="/services/admissions-strategy" className={styles.link}> <h3>Academic Strategy</h3> </Link>
              <ul>
                <li>Expertise in ensuring you excel in the college admissions landscape based on your target schools.</li>
                <li>Tailored admission techniques.</li>
                <li>Direct connection with former admissions officers and advisors.</li>
                <li>Personalized application planning.</li>                
              </ul>
            </div>
            <div className={classNames(styles.card2, "order-md-2")}>
              <Link to="/services/tuition-planning" className={styles.link}><h3>Tuition Planning</h3> </Link>
              <ul>              
                <li>Optimize your financial aid eligibility with resources provided by certified financial professionals and aid officers.</li>
                <li>Plan with proper asset allocation to ensure you qualify for the most monetary support possible.</li>
                <li>Expert appeal process assistance is available.</li>
              </ul>
            </div>
          </div>

          <div className={classNames(styles["unique-service-section"], "row")}>
            <div className={classNames(styles.card1, "col-md-6", "order-md-1")}>
              <Link to="/services/essay-assistance" className={styles.link}><h3>Essay Assistance</h3> </Link>
              <ul>
                <li>Tailored guidance from brainstorming development and crafting your voice.</li>
                <li>Targeted support for supplemental essays tailored to specific schools.</li>
                <li>Enhance your essays with professional editors.</li>
              </ul>
            </div>
            <div className={classNames(styles.card2, "col-md-6", "order-md-2")}>
              <Link to="/services/admissions-strategy" className={styles.link}><h3>College Planning (NEEDS UPDATING)</h3> </Link>
              <p>
                Our college planning tools are designed to help you navigate tthe complex application process with ease.
              </p>
              <ul>
                <li>Customized college lists</li>
                <li>Application deadline tracking</li>
                <li>Personalized timeline management</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      {/* Scrolling Colleges*/}
      <CollegesAd />

      <section className={classNames(styles.join, "py-5")}>
        <div className={classNames(styles.container, "container", "mb-5")}>
          <Link to="/student-interest-form" className={classNames(styles.btn, "btn", "mt-4")}>Get Started Today!</Link>

          {/* <h2 className="display-4">Start Your Journey</h2>
          <p className="lead">Join ClearPillar today and take the first step towards your academic success.</p>
          <Link to="register/student-register" className={classNames(styles.btn, "btn")}>Get Started Today</Link> */}
        </div>
      </section>
    </div>
  );
};
