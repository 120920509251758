// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Register_register-container__\\+dgwP {
  max-width: 650px !important;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.Register_register-container__\\+dgwP p {
  font-size: 0.8rem;
}

.Register_register-container__\\+dgwP .Register_btn__NKKlI {
  margin: 1px;
}

.Register_register-title__xQQP6 {
  color: #0d6efd;
  text-align: center;
}

.Register_register-form-label__8Std- {
  font-weight: bold;
}

.Register_register-form-control__pOfQd {
  border-radius: 10px;
  border: 1px solid #865AFF;
}

.Register_register-form-control__pOfQd textarea {
  resize: none;
}

/* Profile Section */
.Register_profile-picture-container__Ng\\+Je {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.Register_profile-picture-placeholder__y2oUM {
  color: #2c3e50;
}

.Register_profile-picture__p2awF {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/styles/Register.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA,oBAAoB;AACpB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".register-container {\n  max-width: 650px !important;\n  background-color: #fff;\n  padding: 2rem;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.register-container p {\n  font-size: 0.8rem;\n}\n\n.register-container .btn {\n  margin: 1px;\n}\n\n.register-title {\n  color: #0d6efd;\n  text-align: center;\n}\n\n.register-form-label {\n  font-weight: bold;\n}\n\n.register-form-control {\n  border-radius: 10px;\n  border: 1px solid #865AFF;\n}\n\n.register-form-control textarea {\n  resize: none;\n}\n\n/* Profile Section */\n.profile-picture-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.profile-picture-placeholder {\n  color: #2c3e50;\n}\n\n.profile-picture {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register-container": `Register_register-container__+dgwP`,
	"btn": `Register_btn__NKKlI`,
	"register-title": `Register_register-title__xQQP6`,
	"register-form-label": `Register_register-form-label__8Std-`,
	"register-form-control": `Register_register-form-control__pOfQd`,
	"profile-picture-container": `Register_profile-picture-container__Ng+Je`,
	"profile-picture-placeholder": `Register_profile-picture-placeholder__y2oUM`,
	"profile-picture": `Register_profile-picture__p2awF`
};
export default ___CSS_LOADER_EXPORT___;
