import React, { useState } from 'react';
import { Tab, Tabs, Container, Row, Col, Card, Collapse } from 'react-bootstrap';
import flyerexample from 'images/flyerexample.jpg';
import styles from 'styles/Workshops.module.css'

/* to do */
// somehow match up with admin user function of uploading flyers/moving to past events/delete

// center flyer

const Workshops = () => {
  const [open, setOpen] = useState(null);

  const handleToggle = (index) => {
    setOpen(open === index ? null : index);
  };

  const currentEvents = [
    { title: 'Current Event Title Example 1', flyer: flyerexample },
    { title: 'Current Event Title Example 2', flyer: flyerexample },
  ];

  const pastEvents = [
    { title: 'Past Event Title Example 1', flyer: flyerexample },
    { title: 'Past Event Title Example 2', flyer: flyerexample },
  ];

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col>
            <h1>Workshops</h1>
            <p>Information sessions, college application building, and more!</p>
            <Tabs defaultActiveKey="current" className={styles.tabs}>
              <Tab eventKey="current" title="Current Events">
                {currentEvents.map((event, index) => (
                  <Card key={index} className={styles.card}>
                    <Card.Header
                      onClick={() => handleToggle(index)}
                      aria-controls={`collapse-text-${index}`}
                      aria-expanded={open === index}
                      className={`${styles["card-header"]} d-flex justify-content-between align-items-center ${open === index ? styles.active : ''}`}
                    >
                      {event.title}
                      <i className={`fa-solid ${open === index ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'}`}></i>
                    </Card.Header>
                    <Collapse in={open === index}>
                      <div id={`collapse-text-${index}`}>
                        <Card.Body className="align-items-center">
                          {/* <iframe src={event.flyer} width="100%" height="500px" title={event.title}></iframe> */}
                        </Card.Body>
                      </div>
                    </Collapse>
                  </Card>
                ))}
              </Tab>
              <Tab eventKey="past" title="Past Events">
                {pastEvents.map((event, index) => (
                  <Card key={index} className={styles.card}>
                    <Card.Header
                      onClick={() => handleToggle(index + currentEvents.length)} // Adjust index for past events
                      aria-controls={`collapse-text-${index + currentEvents.length}`}
                      aria-expanded={open === index + currentEvents.length}
                      className={`${styles["card-header"]} d-flex justify-content-between align-items-center ${open === index + currentEvents.length ? styles.active : ''}`}
                    >
                      {event.title}
                      <i className={`fa-solid ${open === index + currentEvents.length ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'}`}></i>
                    </Card.Header>
                    <Collapse in={open === index + currentEvents.length}>
                      <div id={`collapse-text-${index + currentEvents.length}`}>
                        <Card.Body>
                          {/* <iframe src={event.flyer} width="100%" height="500px" title={event.title}></iframe> */}
                        </Card.Body>
                      </div>
                    </Collapse>
                  </Card>
                ))}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Workshops;
