// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommunityImpact_page__yBsSj {
    background: linear-gradient(0deg, rgba(15, 6, 42, 1) 0%, rgba(94, 62, 182, 1) 25%, rgba(94, 62, 182, 1) 75%, rgba(15, 6, 42, 1) 100%);
    color: white;
}

.CommunityImpact_container__QN7op {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 10rem;
}

.CommunityImpact_hero__seI7e {
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
}

.CommunityImpact_hero__seI7e h1 {
    font-size: 3rem;
    margin: 0;
}

.CommunityImpact_section__7WXgF {
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border: 1px solid #FFE144;
}

.CommunityImpact_section__7WXgF h2 {
    font-size: 2.5rem !important;
    margin-bottom: 20px;
    text-align: center;
}

.CommunityImpact_section__7WXgF p {
    font-size: 1.2rem !important;
    line-height: 1.8;
    /* text-align: center; */
}
/* 
.mission-section .mission-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-align: left;
}

.mission-section .mission-item img {
    max-width: 150px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mission-section .mission-item p {
    font-size: 1.2rem;
    line-height: 1.8;
    flex: 1;
}

.connection-section a {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
}

.connection-section a:hover {
    text-decoration: underline;
} */`, "",{"version":3,"sources":["webpack://./src/styles/CommunityImpact.module.css"],"names":[],"mappings":"AAAA;IACI,qIAAqI;IACrI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uCAAuC;IACvC,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,wBAAwB;AAC5B;AACA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6BG","sourcesContent":[".page {\n    background: linear-gradient(0deg, rgba(15, 6, 42, 1) 0%, rgba(94, 62, 182, 1) 25%, rgba(94, 62, 182, 1) 75%, rgba(15, 6, 42, 1) 100%);\n    color: white;\n}\n\n.container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding-top: 3rem;\n    padding-bottom: 10rem;\n}\n\n.hero {\n    padding: 40px 20px;\n    text-align: center;\n    border-radius: 10px;\n}\n\n.hero h1 {\n    font-size: 3rem;\n    margin: 0;\n}\n\n.section {\n    padding: 30px;\n    margin-bottom: 30px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n    border: 1px solid #FFE144;\n}\n\n.section h2 {\n    font-size: 2.5rem !important;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.section p {\n    font-size: 1.2rem !important;\n    line-height: 1.8;\n    /* text-align: center; */\n}\n/* \n.mission-section .mission-item {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n    text-align: left;\n}\n\n.mission-section .mission-item img {\n    max-width: 150px;\n    margin-right: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.mission-section .mission-item p {\n    font-size: 1.2rem;\n    line-height: 1.8;\n    flex: 1;\n}\n\n.connection-section a {\n    color: #007BFF;\n    text-decoration: none;\n    font-weight: bold;\n}\n\n.connection-section a:hover {\n    text-decoration: underline;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `CommunityImpact_page__yBsSj`,
	"container": `CommunityImpact_container__QN7op`,
	"hero": `CommunityImpact_hero__seI7e`,
	"section": `CommunityImpact_section__7WXgF`
};
export default ___CSS_LOADER_EXPORT___;
