// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_btn__U\\+Axc {
  background-color: #5E3EB6 ;
  color: #fff;
  padding: 6px 12px;
  margin-bottom: 0;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.Navbar_btn__U\\+Axc:hover {
  background-color: #7D5ED3 ;
  color: #fff;
}

/* .btn:active {
  background-color: #7D5ED3;
  color: #fff;
} */

.Navbar_btn__U\\+Axc.Navbar_show__gbpjf {
  background-color: #7D5ED3 !important;
}

.Navbar_language__WUqV6 {
  display: flex;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,WAAW;AACb;;AAEA;;;GAGG;;AAEH;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".btn {\n  background-color: #5E3EB6 ;\n  color: #fff;\n  padding: 6px 12px;\n  margin-bottom: 0;\n  border-radius: 5px;\n  text-decoration: none;\n  border: none;\n  display: inline-block;\n  vertical-align: middle;\n  font-size: 16px;\n}\n\n.btn:hover {\n  background-color: #7D5ED3 ;\n  color: #fff;\n}\n\n/* .btn:active {\n  background-color: #7D5ED3;\n  color: #fff;\n} */\n\n.btn.show {\n  background-color: #7D5ED3 !important;\n}\n\n.language {\n  display: flex;\n  flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Navbar_btn__U+Axc`,
	"show": `Navbar_show__gbpjf`,
	"language": `Navbar_language__WUqV6`
};
export default ___CSS_LOADER_EXPORT___;
