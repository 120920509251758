import React from "react";
import styles from 'styles/Services.module.css'
import classNames from "classnames";

function TuitionPlanning() {
    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Tuition Planning</h1>
                </div>
                <div className={styles.content}>
                    <section className={styles.section}>
                        <h3 className={styles.motto}>Maximizing Your Financial Aid Potential</h3>
                        <p>
                            Navigating the complexities of college tuition and financial aid can be challenging. At ClearPillar, our Tuition Planning service offers you expert guidance on how to effectively manage and optimize your financial resources, ensuring you get the most out of available financial aid.
                        </p>
                    </section>
                    <div className={styles.banner}>
                        <h2>What we offer</h2>
                    </div>
                    <section className={styles.section}>
                        <h3>Expert Strategies for Financial Aid</h3>
                        <p>
                            Our Tuition Planning experts provide you with specialized advice on how to relocate assets and utilize legal strategies to maximize your financial aid. With insider knowledge of financial aid policies and practices, they help you craft a plan that minimizes your out-of-pocket expenses while maximizing the financial aid and scholarships you receive.
                        </p>
                    </section>
                    <section className={styles.section}>
                        <h3>Your Personal Financial Aid Guide</h3>
                        <p>
                            Our experienced liaisons take the lead in crafting a personalized financial strategy tailored to your specific situation. They work closely with you to understand your financial landscape and develop a plan that positions you to benefit from every available opportunity for aid. From asset relocation to strategic application completion, our liaisons handle the details so you can focus on achieving your academic goals.
                        </p>
                    </section>
                    <section className={styles.section}>
                        <h3>Thorough CSS Profile assistance</h3>
                        <p>
                            Detailed guidance and a two-step review process for the College Scholarship Service Profile to maximize financial aid opportunities.
                        </p>
                    </section>
                    <button className={classNames("btn", styles.btn)}>Get Started with Tuition Planning</button>
                </div>
            </div>
        </div>
    );
}

export default TuitionPlanning;