// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Maintenance_alert__ctgAz {
    --bs-alert-margin-bottom: 0 !important;
    border-radius: 0 !important;
    margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/Maintenance.module.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".alert {\n    --bs-alert-margin-bottom: 0 !important;\n    border-radius: 0 !important;\n    margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `Maintenance_alert__ctgAz`
};
export default ___CSS_LOADER_EXPORT___;
