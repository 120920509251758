import i18n from 'i18next';
import { initReactI18next  } from 'react-i18next';
import translationEN from 'locales/en/translation.json';
import translationZH from 'locales/zh/translation.json';

// may need to create multiple files to organize text by pages
// https://react.i18next.com/legacy-v9/step-by-step-guide#b-loading-multiple-translation-files

const resources = {
    en: {
        translation: translationEN
    },
    zh: {
        translation: translationZH
    }
};

i18n
  .use(initReactI18next ) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
