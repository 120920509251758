// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* only for the AdmissionStrategy.js*/
.ServicesAdmissions_notepad__EFA1N {
    background-color: #fffbea;
    padding: 20px;
    border-left: 5px solid #ffd700;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.ServicesAdmissions_notepad__EFA1N h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3em;
    font-weight: 600;
    color: #1e3c72;
    margin-bottom: 10px;
}

.ServicesAdmissions_notepad__EFA1N p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ServicesAdmissions.module.css"],"names":[],"mappings":";AACA,qCAAqC;AACrC;IACI,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB","sourcesContent":["\n/* only for the AdmissionStrategy.js*/\n.notepad {\n    background-color: #fffbea;\n    padding: 20px;\n    border-left: 5px solid #ffd700;\n    border-radius: 10px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    margin-bottom: 30px;\n}\n\n.notepad h4 {\n    font-family: 'Montserrat', sans-serif;\n    font-size: 1.3em;\n    font-weight: 600;\n    color: #1e3c72;\n    margin-bottom: 10px;\n}\n\n.notepad p {\n    font-size: 1em;\n    line-height: 1.6;\n    color: #666;\n    margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notepad": `ServicesAdmissions_notepad__EFA1N`
};
export default ___CSS_LOADER_EXPORT___;
