import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   // Check if the user is authenticated on component mount
  //   axios.get('http://localhost:8000/profile', { withCredentials: true })
  //     .then(response => {
  //       setIsAuthenticated(true);
  //     })
  //     .catch(() => {
  //       setIsAuthenticated(false);
  //     });
  // }, []);

  const login = async (username, password) => {
    setIsAuthenticated(true);
    // try {
    //   await axios.post('http://localhost:8000/login', { username, password }, { withCredentials: true });
    //   setIsAuthenticated(true);
    // } catch (error) {
    //   console.error('Login failed', error);
    // }
  };

  const logout = async () => {
    setIsAuthenticated(false);
    // try {
    //   await axios.post('http://localhost:8000/logout', {}, { withCredentials: true });
    //   setIsAuthenticated(false);
    // } catch (error) {
    //   console.error('Logout failed', error);
    // }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
