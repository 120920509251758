import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'styles/EssayBrainstorming.module.css'

export default function EssayBrainstorming() {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles["header-encap"]}>
          <div className={styles.stars}>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
          </div>
          <div className={styles["header-content"]}><h1>Need Help Writing Your Common App Essay?</h1></div>
          <div className={styles.stars}>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
          </div>
        </div>
        <div className={styles["line-div"]}>
          <hr className={styles.line} />
        </div>

        <div className={styles.content}>
          <div className={styles["left-side"]}>
            <h3>Common Application blues?</h3>
            <p>
              Find clarity and creativity with ClearPillar.
            </p>
            <p>Our brainstorming tool is generated with prompts to initiate your 
              ideas and find your voice.
            </p>
            <div className='text-center'>
              <button className={classNames("btn", styles.btn)}>
                <i className="fa-solid fa-pen pe-2"></i> Try Now
              </button>
            </div>
          </div>
          <div className={styles["right-side"]}>
            <h3>Personalized Help</h3>
            <p>
              Discover how to capture your story and experience personalized 
              support to craft your potential.
            </p>
            <div className='text-center'>
              <Link to="/services/essay-assistance" className={classNames("btn", styles.btn)}>
                <i className="fa-solid fa-user-graduate pe-2"></i> Explore Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
