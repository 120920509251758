import React from 'react';

function Privacy() {
    return (
        <div>
            <h1>Privacy</h1>
            <p>We are committed to balancing parental involvement with respect for student privacy. Our comprehensive updates ensure that personal stories and sensitive details are private, and respected, fostering trust and comfort. Our liaisons are trained to communicate effectively while safeguarding the student's privacy. This ensures parents stay informed without compromising their child's personal experiences.</p>
        </div>
    );
}

export default Privacy;