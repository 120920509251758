import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3001/api/forgot-password', { email });
      console.log("response", response);
      if (response.status === 200) {
        setMessage('Success! Password reset link sent to your email address.');
        setError('');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('Email address not found. Please check and try again.');
      } else {
        setError('Failed to send password reset link. Please try again.');
      }
      setMessage('');
    }
  };

  return (
    <div>
      <h1>Forgot Password</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <h3>Email Address</h3>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="email"
            required
          />
        </label>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="btn">Send Reset Link</button>
      </form>
    </div>
  );
}
