import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import profile_img from 'images/profile-placeholder.png';
import styles from 'styles/Dashboard.module.css';
import Profile from '@profile/Profile'
import axios from 'axios';
import dateFormat from 'utils/dateFormat'

// placeholder meetings
// const meetings = [
//     { id: 1, title: 'Meeting 1', date: '1' },
//     { id: 2, title: 'Meeting 2', date: '01-02-2024' },
//     { id: 3, title: 'Meeting 3', date: '01-03-2024' },
//     { id: 4, title: 'Meeting 4', date: '01-04-2024' },
//     { id: 5, title: 'Meeting 5', date: '01-05-2024' },
//     { id: 6, title: 'Meeting 6', date: '01-06-2024' },
//     { id: 7, title: 'Meeting 7', date: '01-07-2024' },
//     { id: 8, title: 'Meeting 8', date: '01-08-2024' },
//     { id: 9, title: 'Meeting 9', date: '01-09-2024' },
//     { id: 10, title: 'Meeting 10', date: '01-10-2024' },
//     { id: 11, title: 'Meeting 11', date: '01-06-2024' },
//     { id: 12, title: 'Meeting 12', date: '01-07-2024' },
//     { id: 13, title: 'Meeting 13', date: '01-08-2024' },
//     { id: 14, title: 'Meeting 14', date: '01-09-2024' },
//     { id: 15, title: 'Meeting 15', date: '01-10-2024' }
// ];

function Dashboard(props) {
    const navigate = useNavigate();
    const [meetings, setMeetings] = useState([]);
    const [upcomingMeetings, setUpcomingMeetings] = useState([])
    const [currentPg, setCurrentPg] = useState(1);
    const meetingsPerPg = 5;

    const indexEnd = currentPg * meetingsPerPg;
    const indexStart = indexEnd - meetingsPerPg;
    const endPg = Math.ceil(meetings.length / meetingsPerPg);

    useEffect(() => {
        const fetchMeetings = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get('http://localhost:3001/api/meetings', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setMeetings(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchMeetings();
    }, []);

    useEffect(() => {
        const currentTime = new Date();
        const filteredUpcomingMeetings = meetings.filter(meeting => {
            const meetingTime = new Date(meeting.meeting_date);
            return meetingTime > currentTime; // Filter for future meetings only
        });
        console.log(filteredUpcomingMeetings)
        setUpcomingMeetings(filteredUpcomingMeetings);
    }, [meetings])

    // const currMeetings = meetings.slice(indexStart, indexEnd).map((m) => {
    //     return (
    //         <tr key={m.id}>
    //             <td>{dateFormat(m.meeting_date)}</td>
    //             <td>{m.student_id}</td>
    //         </tr>
    //     )
    // })

    const currMeetings = upcomingMeetings.slice(indexStart, indexEnd).map((m) => {
        return (
            <tr key={m.id}>
                <td>{dateFormat(m.meeting_date)}</td>
                <td>{m.student_id}</td>
            </tr>
        )
    })

    const prevPg = () => {
        if (currentPg > 1) {
            setCurrentPg(currentPg - 1);
        }
    }
    const nextPg = () => {
        if (currentPg < endPg) {
            setCurrentPg(currentPg + 1);
        }
    }

    const handleAllMeetingsClick = () => {
        navigate('/dashboard/all-meetings', { state: { meetings } })
    }

    const handleProfile = () => {
        console.log("clicked img!")
        navigate('/profile')
    }

    return (
        <div className={styles.box}>

            <div className={styles.col}>
                <h1>Hello, [insert name].</h1>

                {/* TO DO: have to dynamically grab the students and team, and then add td with img and name for each. if td < 7, add empty td */}
                {/* TO DO: pass the name/id from database? as prop when viewing profile. */}
                {/* css issue: how to have background show only if non-empty <td> */}
                {/* clicking issue of profile: not entire cell clickable -> profile, only seems to be near the img... */}
                <div>
                    <h3>Assigned Students</h3>
                    <Table className={[styles.no_border, styles.center].join(' ')}>
                        <tbody>
                            <tr>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 1" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 1</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 2" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 2</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 3" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 3</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 4" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 4</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 5" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 5</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 6" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 6</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 7" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} onClick={handleProfile} /><br />
                                    <span>Student 7</span>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div>
                    <h3>Your Team</h3>
                    <Table className={[styles.no_border, styles.center].join(' ')}>
                        <tbody>
                            <tr>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 1" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} /><br /><span>Liaison 1</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 2" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} /><br /><span>Professional 1</span>
                                </td>
                                <td className={styles.profile}>
                                    <img src={profile_img} alt="name 3" style={{ height: "80px", width: "80px", paddingBottom: "10px" }} /><br /><span>Professional 2</span>
                                </td>
                                <td>
                                    <img alt=""></img><br /><span></span>
                                </td>
                                <td>
                                    <img alt=""></img><br /><span></span>
                                </td>
                                <td>
                                    <img alt=""></img><br /><span></span>
                                </td>
                                <td>
                                    <img alt=""></img><br /><span></span>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>

            {/* Need to dynamically grab the meeting details from server */}
            <div className={styles.col}>
                <div>
                    <h3>Upcoming Meetings</h3>
                    <button onClick={handleAllMeetingsClick}>All meetings</button>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Student</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currMeetings}
                        </tbody>
                    </Table>
                    <div className={styles.right}>
                        <button onClick={prevPg} disabled={currentPg === 1}>&#60;</button>
                        <button onClick={nextPg} disabled={currentPg === endPg || endPg === 0}>&#62;</button>
                    </div>
                    
                </div>

                {/* <div>
                    <h3>Required Actions</h3>
                    <Table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th colSpan={3}>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.red}>Upload Meeting Notes</td>
                                <td>01/02/2023</td>
                                <td>3:00 PM</td>
                                <td>Joe Doe</td>
                            </tr>
                        </tbody>
                    </Table>
                </div> */}
            </div>

        </div>
    );
}

export default Dashboard;