// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* liaison, communicaton mode */
.RegisterLiaison_communication-option__dWQW- {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.RegisterLiaison_communication-option__dWQW- input[type="checkbox"] {
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative
}

.RegisterLiaison_communication-option__dWQW- input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.RegisterLiaison_communication-option__dWQW- input[type="checkbox"]:checked::after {
  content: '\\2713';
  color: white;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/styles/RegisterLiaison.module.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,oDAAoD;EACpD;AACF;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":["/* liaison, communicaton mode */\n.communication-option {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.communication-option input[type=\"checkbox\"] {\n  width: 20px;\n  height: 20px;\n  appearance: none;\n  border: 2px solid #007bff;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s, border-color 0.3s;\n  position: relative\n}\n\n.communication-option input[type=\"checkbox\"]:checked {\n  background-color: #007bff;\n  border-color: #007bff;\n}\n\n.communication-option input[type=\"checkbox\"]:checked::after {\n  content: '\\2713';\n  color: white;\n  font-size: 18px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"communication-option": `RegisterLiaison_communication-option__dWQW-`
};
export default ___CSS_LOADER_EXPORT___;
